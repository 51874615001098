import { Can } from 'providers/casl'

export const onWorkspaceAccess = (can: Can) => {
  can('access', 'WorkspacePage-list')
  can('access', 'WorkspacePage-add')
  can('access', 'WorkspacePage-edit')
  can('have', 'EmployeeElement')
}

export const onWorkspaceDelete = (can: Can) => {
  can('delete', 'Workspace')
}

export const onWorkspaceSelectUse = (can: Can) => {
  can('use', 'WorkspaceFeature-select')
}
