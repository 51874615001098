import { Components, Theme } from '@mui/material'

export const createMuiSwitch = (theme: Theme): Components['MuiSwitch'] => ({
  styleOverrides: {
    root: {
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.movement.main,
            opacity: 1,
          },
          '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.movement.main,
            },
          },
          color: theme.palette.white.main,
          transform: 'translateX(14px)',
        },
        '&.Mui-disabled': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[400],
            opacity: 0.3,
          },
          '&.Mui-checked': {
            color: theme.palette.white.main,
          },
          color: theme.palette.white.main,
        },
        color: theme.palette.white.main,
        padding: 3,
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'unset',
        height: 14,
        width: 14,
      },
      '& .MuiSwitch-track': {
        backgroundColor: theme.palette.grey[400],
        borderRadius: 10,
        opacity: 1,
      },
      alignSelf: 'center',
      height: 20,
      margin: '0px 10px 0px 10px',
      padding: 0,
      width: 34,
    },
  },
})
