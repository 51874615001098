import { useContext } from 'react'

import { Table } from 'components/Table'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { MediaTypeDefaultAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { useColumns } from './useColumns'

export const DefaultTable = (): JSX.Element => {
  const { adUnit } = useContext(AddEditAdUnitContext)
  const columns = useColumns()

  if (!adUnit) {
    throw new Error('Ad unit should be accessible')
  }

  const data: MediaTypeDefaultAttribute[] = [adUnit.mediaTypes.defaultAttribute]

  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}
