import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { AddSchema } from './schemas/addSchema'

export const CommonForm = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { control } = useFormContext<AddSchema>()

  const cannotCreateOrUpdateDomain: boolean =
    ability.cannot('create', 'Domain') || ability.cannot('update', 'Domain')

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.general.basicAttributes.subtitle')}
        title={t('form.general.basicAttributes.title')}
      />

      <GapBox />

      <BoxForm>
        <TextField
          control={control}
          disabled={cannotCreateOrUpdateDomain}
          label={t('form.general.basicAttributes.name.label')}
          name='name'
          placeholder={t('form.general.basicAttributes.name.placeholder')}
        />
      </BoxForm>
    </Paper>
  )
}
