import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { GapBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import Table from 'features/inventory/domain/components/DomainsTable'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'

export const DomainList = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/domain')
  const [filters, setFilters] = useState<FilterValue[]>([])

  const DomainsFilter = (
    <Filter
      id='domainsFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  const AddNew = (): JSX.Element => (
    <Link to={generatePath(ROUTES.INVENTORY.DOMAIN.ADD, { workspaceId })}>
      <Button
        data-cy='domain-button-add'
        variant='contained'
      >
        {t('list.add')}
      </Button>
    </Link>
  )

  const canCreate: boolean = ability.can('create', 'Domain')

  return (
    <Paper>
      <TableHeader
        actions={canCreate ? <AddNew /> : undefined}
        description={canCreate ? t('list.description.employee') : t('list.description.viewer')}
        filter={DomainsFilter}
        title={t('list.title')}
      />

      <GapBox />

      <Table filters={filters} />
    </Paper>
  )
}
