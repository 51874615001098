import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { GapBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { KNOWLEDGE_BASE } from 'features/globalSetup/constants'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'
import Table from './BiddersTable'

export const PrebidBiddersPage = (): JSX.Element => {
  const ability = useAbility()
  const navigate = useNavigate()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const [filters, setFilters] = useState<FilterValue[]>([])

  const BiddersFilter = (
    <Filter
      id='biddersFilter'
      onChange={setFilters}
      placeholder={t('prebid.bidders.list.filter')}
      values={filters}
    />
  )

  const AddButton = (): JSX.Element => (
    <Button
      data-cy='add-bidder-button'
      onClick={(): void =>
        navigate(generatePath(ROUTES.GLOBAL_SETUP.PREBID.BIDDERS.ADD, { workspaceId }))
      }
      variant='contained'
    >
      {t('common:actions.addNew')}
    </Button>
  )

  const canGoToAddBidderPage: boolean = ability.can('access', 'GlobalSetupPage-prebid-bidders-add')

  return (
    <Paper>
      <TableHeader
        actions={canGoToAddBidderPage ? <AddButton /> : undefined}
        description={t('prebid.bidders.list.description')}
        descriptionEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE.PREBID_BIDDERS,
            }}
          />
        }
        filter={BiddersFilter}
        title={t('prebid.bidders.list.title')}
      />

      <GapBox />

      <Table filters={filters} />
    </Paper>
  )
}
