import { Can } from 'providers/casl'

export const onGlobalSetupGoogleAdManagerConnectionAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-googleAdManagerConnection')
}

export const onGlobalSetupGoogleAdManagerConnectionUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-googleAdManagerConnection-connect')
  can('update', 'GlobalSetupFeature-googleAdManagerConnection-setupPrebidStack')
}
