import _ from 'lodash'
import { useContext } from 'react'
import { To } from 'react-router-dom'

import { useMergeFormData } from 'components/Form'
import { Table } from 'components/Table'
import { useSort } from 'components/Table/Sort'
import { canAccess } from 'features/globalSetup/utils/auth'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { DomainPrebidModulesAttributesSchema, Schema } from '../../../../schema'
import { DEFAULT_SORT, SORTABLE_COLUMNS } from './constants'
import { useGetDomainPrebidModules } from './hooks/useGetDomainPrebidModules'
import { DomainPrebidModuleSlice, SortBy } from './hooks/useGetDomainPrebidModules/types'
import { prebidModulePathGenerator } from './prebidModulePathGenerator'
import { ModulesTableProps } from './types'
import { useColumns } from './useColumns'

export const ModuleTable = ({ moduleType }: ModulesTableProps): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { domain } = useContext(AddEditDomainContext)

  if (!domain) {
    throw new Error('Domain should be accessible')
  }

  const columns = useColumns(moduleType)
  const { props: sortProps, sort } = useSort<DomainPrebidModuleSlice, SortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )
  const { loading, modules } = useGetDomainPrebidModules({ domainId: domain.id, moduleType, sort })

  const domainPrebidModulesAttributes: DomainPrebidModulesAttributesSchema['domainPrebidModulesAttributes'] =
    _.chain(modules).keyBy('id').mapValues('enabled').value()

  useMergeFormData<Schema>({
    data: domainPrebidModulesAttributes,
    path: 'domainPrebidModulesAttributes',
  })

  if (modules && modules.length === 0) {
    return <></>
  }

  const redirectToModulePage = (row: DomainPrebidModuleSlice): To => {
    const {
      workspacePrebidModule: { id },
    } = row

    return prebidModulePathGenerator({ id, moduleType, tab: 'general', workspaceId })
  }

  const handleRowClick: ((row: DomainPrebidModuleSlice) => To) | undefined = canAccess({
    ability,
    moduleType,
  })
    ? (row: DomainPrebidModuleSlice): To => redirectToModulePage(row)
    : undefined

  return (
    <Table
      columns={columns}
      data={modules}
      data-cy={`domain-modules-${moduleType}-table`}
      loading={loading}
      onClickRow={handleRowClick}
      sort={sortProps}
    />
  )
}
