export const KNOWLEDGE_BASE_URL = {
  CONSENT_MANAGEMENT: 'https://knowledge-base.yieldbird.com/prebid-stack/consent-management',
  GDPR_TCF_TIMEOUT_ENABLED: 'https://docs.prebid.org/dev-docs/modules/consentManagementTcf.html',
  GPP_CONTROL_US_NAT: 'https://docs.prebid.org/dev-docs/modules/gppControl_usnat.html',
  GPP_CONTROL_US_STATES: 'https://docs.prebid.org/dev-docs/modules/gppControl_usstates.html',
  GPP_TIMEOUT_ENABLED: 'https://docs.prebid.org/dev-docs/modules/consentManagementGpp.html',
  NON_CONSENT_TRAFFIC_MONETIZATION:
    'https://knowledge-base.yieldbird.com/prebid-stack/non-consent-traffic-monetization',
  PREBID_VIDEO: {
    INPUT: 'https://docs.prebid.org/prebid-video/video-module.html',
    SUBTITLE:
      'https://knowledge-base.yieldbird.com/frequently-asked-questions/how-to-integrate-prebid-stack-on-an-instream-video-ad-unit',
  },
  TIMEOUTS: 'https://knowledge-base.yieldbird.com/prebid-stack/timeouts',
  US_PRIVACY_CCPA_TIMEOUT_ENABLED:
    'https://docs.prebid.org/dev-docs/modules/consentManagementUsp.html',
} as const
