import { capitalCase } from 'change-case'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmployeeBox, GapBox } from 'components/Box'
import { ContentCopy } from 'components/ContentCopy'
import { BoxForm, Select, Switch, TextField, TransferList } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { SelectOption } from 'components/Select'
import { OutOfPageType, outOfPageTypeValues } from 'features/inventory/ad-unit/types/outOfPageType'
import {
  DomainSlice,
  useGetDomainsForSelect,
} from 'features/inventory/domain/hooks/useGetDomainsForSelect'
import { PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL } from 'features/inventory/page/constants'
import { useAbility } from 'providers/casl'
import { useGetPagesForAdUnitTransferList } from './hooks/useGetPagesForAdUnitTransferList'
import { CommonSchema } from './schemas/commonSchema'
import { MediaTypesSection } from './sections/MediaTypesSection'
import { CommonFormProps } from './types'

export const CommonForm = ({ variant }: CommonFormProps): JSX.Element => {
  const { t } = useTranslation(['features/adUnit', 'common'])
  const ability = useAbility()
  const { control, watch } = useFormContext<CommonSchema>()
  const { domains } = useGetDomainsForSelect()

  const { domainId, outOfPage, rawId } = watch()
  const { active, available, loading } = useGetPagesForAdUnitTransferList({ domainId })
  const showTransferList = !loading && domainId.length > 0
  const cannotCreateOrUpdateAdUnit =
    ability.cannot('create', 'AdUnit') || ability.cannot('update', 'AdUnit')

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.general.basicAttributes.subtitle')}
          title={t('form.general.basicAttributes.title')}
        />

        <GapBox />

        <BoxForm>
          <Select<CommonSchema, DomainSlice>
            control={control}
            disabled={variant === 'edit'}
            label={t('form.general.basicAttributes.domainId')}
            name='domainId'
            options={domains}
          />

          <TextField
            control={control}
            disabled={cannotCreateOrUpdateAdUnit}
            label={t('form.general.basicAttributes.name.label')}
            name='name'
            placeholder={t('form.general.basicAttributes.name.placeholder')}
          />

          <TextField
            control={control}
            disabled={variant === 'edit'}
            label={t('form.general.basicAttributes.path.label')}
            name='path'
            placeholder={t('form.general.basicAttributes.path.placeholder')}
            tooltip={{ content: t('form.general.basicAttributes.path.tooltip') }}
          />

          {variant === 'edit' && ability.can('read', 'AdUnitField-rawId') && (
            <EmployeeBox>
              <TextField
                control={control}
                disabled
                InputProps={{
                  endAdornment: <ContentCopy text={String(rawId)} />,
                }}
                label={t('form.general.basicAttributes.rawId.label')}
                name='rawId'
                tooltip={{ content: t('form.general.basicAttributes.rawId.tooltip') }}
              />
            </EmployeeBox>
          )}

          <TextField
            control={control}
            disabled={cannotCreateOrUpdateAdUnit}
            label={t('form.general.basicAttributes.divId.label')}
            name='divId'
            placeholder={t('form.general.basicAttributes.divId.placeholder')}
            tooltip={{ content: t('form.general.basicAttributes.divId.tooltip') }}
          />

          <Switch
            control={control}
            disabled={cannotCreateOrUpdateAdUnit}
            label={t('form.general.basicAttributes.outOfPage')}
            name='outOfPage'
          />

          {outOfPage === true && (
            <Select<CommonSchema, OutOfPageType>
              control={control}
              disabled={cannotCreateOrUpdateAdUnit}
              label={t('form.general.basicAttributes.outOfPageType.label')}
              name='outOfPageType'
              options={outOfPageTypeValues.map(
                (type: OutOfPageType): SelectOption<OutOfPageType> => ({
                  label: capitalCase(type),
                  value: type,
                }),
              )}
              tooltip={{ content: t('form.general.basicAttributes.outOfPageType.tooltip') }}
            />
          )}
        </BoxForm>
      </Paper>

      {variant === 'edit' && <MediaTypesSection />}

      {showTransferList && (
        <Paper>
          <SectionHeader
            subtitle={t('form.general.pageConnection.subtitle')}
            subtitleEndAdornment={
              <Link
                newTab
                to={PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL}
              >
                {t('common:learnMore')}
              </Link>
            }
            title={t('form.general.pageConnection.title')}
          />

          <GapBox />

          <TransferList
            control={control}
            disabled={cannotCreateOrUpdateAdUnit}
            leftList={available}
            name='pageIds'
            rightList={active}
          />
        </Paper>
      )}

      {ability.can('read', 'AdUnitField-unfilledRecovery') && (
        <Paper variant='cream'>
          <SectionHeader
            subtitle={t('form.general.unfilledRecoveryAdUnit.subtitle')}
            title={t('form.general.unfilledRecoveryAdUnit.title')}
            titleEndAdornment={
              <Switch
                control={control}
                disabled={
                  variant === 'edit' ||
                  cannotCreateOrUpdateAdUnit ||
                  ability.cannot('update', 'AdUnitField-unfilledRecovery')
                }
                name='unfilledRecoveryAdUnit'
              />
            }
          />

          <GapBox />
        </Paper>
      )}
    </>
  )
}
