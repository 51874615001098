import { Can } from 'providers/casl'

export const onGlobalSetupPrebidProtectionAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-protection-list')
  can('access', 'GlobalSetupPage-prebid-protection-edit')
}

export const onGlobalSetupPrebidDefaultsAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-defaults')
}

export const onGlobalSetupPrebidDefaultsUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-prebidDefaults')
}

export const onGlobalSetupPrebidBiddersAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-bidders')
  can('access', 'GlobalSetupPage-prebid-bidders-edit')
}

export const onGlobalSetupPrebidModuleAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-modules')
  can('access', 'GlobalSetupPage-prebid-modules-general-edit')
  can('access', 'GlobalSetupPage-prebid-modules-user-id-add')
  can('access', 'GlobalSetupPage-prebid-modules-user-id-edit')
  can('access', 'GlobalSetupPage-prebid-modules-vendor-specific-add')
  can('access', 'GlobalSetupPage-prebid-modules-vendor-specific-edit')
  can('access', 'GlobalSetupPage-prebid-modules-analytics-add')
  can('access', 'GlobalSetupPage-prebid-modules-analytics-edit')
}

export const onGlobalSetupPrebidModuleCUD = (can: Can) => {
  can('update', 'GlobalSetupFeature-general')
  can('create', 'GlobalSetupFeature-userId')
  can('update', 'GlobalSetupFeature-userId')
  can('delete', 'GlobalSetupFeature-userId')
  can('create', 'GlobalSetupFeature-vendorSpecific')
  can('update', 'GlobalSetupFeature-vendorSpecific')
  can('delete', 'GlobalSetupFeature-vendorSpecific')
  can('create', 'GlobalSetupFeature-analytics')
  can('update', 'GlobalSetupFeature-analytics')
  can('delete', 'GlobalSetupFeature-analytics')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsOwnRead = (can: Can) => {
  can('read', 'GlobalSetupField-prebid-modules-inventory-connection-params-own')
}

export const onGlobalSetupBiddersCUD = (can: Can) => {
  can('create', 'GlobalSetupFeature-bidder')
  can('update', 'GlobalSetupFeature-bidder')
  can('delete', 'GlobalSetupFeature-bidder')
}

export const onGlobalSetupPrebidBiddersAddaccess = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-bidders-add')
}

export const onGlobalSetupBiddersOwnUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderCode')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidderSid')
  can('update', 'GlobalSetupField-prebid-bidders-own-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-own-priority')
  can('update', 'GlobalSetupField-prebid-bidders-own-consentMode')
}

export const onGlobalSetupBiddersThirdPartyUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-supplyChain')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderCode')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidderSid')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-priority')
  can('update', 'GlobalSetupField-prebid-bidders-thirdPartyProvider-consentMode')
}

export const onGlobalSetupBiddersYieldbirdUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-bidderId')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-priority')
}

export const onGlobalSetupBiddersYieldbirdCpmConsentStatusUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-bidCpmAdjustment')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-consentMode')
  can('update', 'GlobalSetupField-prebid-bidders-yieldbird-status')
}

export const onGlobalSetupBiddersBidParamsCommonUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-priority')
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-cs')
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-s2s')
}

export const onGlobalSetupBiddersBidParamsOwnUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-own')
}

export const onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-thirdPartyProvider')
}
export const onGlobalSetupBiddersBidParamsYieldbirdUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-bidders-bid-params-bid-param-yielbdird')
}

export const onGlobalSetupPrebidModulesGeneralParamsOwnUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-general-params-own')
}

export const onGlobalSetupPrebidModulesGeneralParamsYieldbirdUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-general-params-yieldbird')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-inventory-connection-params-own')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-inventory-connection-params-yieldbird')
}

export const onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead = (can: Can) => {
  can('read', 'GlobalSetupField-prebid-modules-inventory-connection-params-yieldbird')
}

export const onGlobalSetupPrebidModuleStatusUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-prebid-modules-status')
}

export const onGlobalSetupProtectionACUD = (can: Can) => {
  can('access', 'GlobalSetupPage-prebid-protection-add')
  can('create', 'GlobalSetupFeature-protection')
  can('update', 'GlobalSetupFeature-protection')
  can('delete', 'GlobalSetupFeature-protection')
}
