import { Can } from 'providers/casl'

export const onGlobalSetupAmazonAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-amazon')
  can('access', 'GlobalSetupPage-amazon-general')
  can('access', 'GlobalSetupPage-amazon-inventory-connection')
}

export const onGlobalSetupAmazonUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-amazon')
}

export const onGlobalSetupAmazonYieldbirdAccountStatusUpdate = (can: Can) => {
  can('update', 'GlobalSetupFeature-amazon-yieldbird-account-status')
}
