import { Can } from 'providers/casl'

export const onAdUnitAccess = (can: Can) => {
  can('access', 'AdUnitPage-list')
  can('access', 'AdUnitPage-add')
  can('access', 'AdUnitPage-edit')
}

export const onAdUnitCreateUpdateDelete = (can: Can) => {
  can('create', 'AdUnit')
  can('update', 'AdUnit')
  can('delete', 'AdUnit')
}

export const onAdUnitFieldsRead = (can: Can) => {
  can('read', 'AdUnitField-rawId')
  can('read', 'AdUnitField-unfilledRecovery')
}

export const onAdUnitUnfilledRecoveryUpdate = (can: Can) => {
  can('update', 'AdUnitField-unfilledRecovery')
}

export const onAdUnitMediaTypesCUD = (can: Can) => {
  can('create', 'AdUnitFeature-mediaTypes')
  can('update', 'AdUnitFeature-mediaTypes')
  can('delete', 'AdUnitFeature-mediaTypes')
}
