import { Can } from 'providers/casl'

export const onProductAccess = (can: Can) => {
  can('access', 'ProductPage-list')
}

export const onProductUpdate = (can: Can) => {
  can('update', 'Product')
}

export const onProductStatusReadUpdate = (can: Can) => {
  can('read', 'ProductField-status')
  can('update', 'ProductField-status')
}
