import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { BiddersTable } from './components/BiddersTable'
import { KNOWLEDGE_BASE_URL } from './constants'

export const BiddersSection = (): JSX.Element => {
  const { t } = useTranslation(['features/domain', 'common'])
  const [filters, setFilters] = useState<FilterValue[]>([])

  const BiddersFilter = (
    <Filter
      id='biddersFilter'
      onChange={setFilters}
      placeholder={t('common:filter.search')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        description={t('form.prebidStack.bidders.list.description')}
        descriptionEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL.PREBID_BIDDERS,
            }}
          />
        }
        filter={BiddersFilter}
        title={t('form.prebidStack.bidders.list.title')}
      />

      <GapBox />

      <BiddersTable filters={filters} />
    </Paper>
  )
}
