import { useFormContext } from 'react-hook-form'

import { Switch } from 'components/Form'
import { useDataForAdUnitBidderSwitch } from 'features/globalSetup'
import { Schema } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/form/schema'
import { useExistingWorkspaceBidder } from 'features/globalSetup/pages/Prebid/Bidders/EditBidderPage/pages/BidParamsAndConnection/hooks/useExistingWorkspaceBidder'
import { useAbility } from 'providers/casl'
import { EnableBidderToggleCellProps } from './types'
import { useDisableByEmptyRequiredParams } from './useDisableByEmptyRequiredParams'

export const EnableBidderToggleCell = ({
  cellContext,
  control,
  kind,
  requiredParams,
  workspaceStatus,
}: EnableBidderToggleCellProps): JSX.Element => {
  const {
    cell: { getValue },
    column: { id: accessorKey },
    row: {
      original: { id: adUnitBidderId },
    },
  } = cellContext

  const {
    workspaceBidder: { accountType },
  } = useExistingWorkspaceBidder()
  const ability = useAbility()

  const { watch } = useFormContext<Schema>()
  const { adUnitBidders } = watch()

  useDisableByEmptyRequiredParams({
    adUnitBidderId,
    kind,
    requiredParams,
  })
  const inputName: string = `adUnitBidders.${adUnitBidderId}.${accessorKey}`
  const isYieldbirdAccount: boolean = accountType === 'yieldbird'

  const { isDisabled, tooltip } = useDataForAdUnitBidderSwitch({
    isDisabledByAuth: ability.cannot(
      'update',
      `GlobalSetupField-prebid-bidders-bid-params-${kind}`,
    ),
    isDisabledByRequiredParams: adUnitBidders[adUnitBidderId]?.[`${kind}DisabledByRequiredParams`],
    isDisabledByYieldbirdDenied: isYieldbirdAccount && workspaceStatus === 'denied',
    isDisabledByYieldbirdRequested: isYieldbirdAccount && workspaceStatus === 'requested',
    paramsKind: kind,
  })

  return (
    <Switch<Schema>
      control={control}
      disabled={isDisabled}
      name={inputName}
      tooltip={tooltip}
      tooltipMode='wrap'
      value={getValue()}
    />
  )
}
