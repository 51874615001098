import { Download, Upload } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { GapBox } from 'components/Box'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import UploadDialog from './components/UploadDialog'
import { useDownloadAdUnitsConfiguration } from './hooks/useDownloadAdUnitsConfiguration'
import { BulkManagementUrlParams } from './types'

export const BulkManagement = (): JSX.Element => {
  const { id: domainId } = useParams<BulkManagementUrlParams>()
  const { domain } = useContext(AddEditDomainContext)
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  if (!domainId || !domain) {
    throw Error('Domain should be accessible')
  }

  const cannotCreateDomainBulkManagement: boolean = ability.cannot(
    'create',
    'DomainFeature-bulkManagement',
  )

  const { download, loading: loadingDownload } = useDownloadAdUnitsConfiguration({
    domainName: domain.name,
  })

  const Header = (): JSX.Element => (
    <SectionHeader
      subtitle={t('form.general.bulkManagement.header.subtitle')}
      subtitleEndAdornment={
        <Link
          newTab
          to={KNOWLEDGE_BASE_URL.BULK_MANAGEMENT}
        >
          {t('common:learnMore')}
        </Link>
      }
      title={t('form.general.bulkManagement.header.title')}
    />
  )

  const Actions = (): JSX.Element => (
    <Stack
      direction='row'
      spacing={2}
    >
      <Button
        disabled={loadingDownload}
        onClick={() => download(domainId)}
        startIcon={<Download />}
        variant='contained'
      >
        {t('form.general.bulkManagement.buttons.download')}
      </Button>

      <Button
        disabled={cannotCreateDomainBulkManagement}
        onClick={() => void setIsDialogOpen(true)}
        startIcon={<Upload />}
        variant='contained'
      >
        {t('form.general.bulkManagement.buttons.upload')}
      </Button>
    </Stack>
  )

  return (
    <>
      <Paper>
        <Header />
        <GapBox />
        <Actions />
      </Paper>

      <UploadDialog
        domainId={domainId}
        isOpen={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
    </>
  )
}
