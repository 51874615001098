import { SvgIcon } from '@mui/material'
import { kebabCase } from 'lodash'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ListItemButton } from 'components/List'
import Loading from 'components/Loading'
import { Product } from 'features/product'
import { productsLogos } from 'features/product/utils/productsLogos'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ProductsItemsProps } from './types'

export const ProductsItems = ({ editPath, id, products }: ProductsItemsProps): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/product')
  const navigate = useNavigate()

  const LogoIcon = ({ product }: { product: Product }): JSX.Element => (
    <Suspense fallback={<Loading size={24} />}>
      <SvgIcon
        component={productsLogos[product]}
        inheritViewBox
        sx={{ filter: 'grayscale(1)' }}
      />
    </Suspense>
  )

  const navigateToEdit = (product: Product): void =>
    navigate(
      generatePath(editPath, {
        id,
        tab: kebabCase(product),
        workspaceId,
      }),
    )

  return (
    <>
      {products.map((product: Product): JSX.Element | undefined =>
        ability.can('access', `ProductForm-${product}`) ? (
          <ListItemButton
            data-cy={`actions-${product}`}
            icon={<LogoIcon product={product} />}
            key={product}
            onClick={() => navigateToEdit(product)}
            text={t(`product.${product}`)}
          />
        ) : undefined,
      )}
    </>
  )
}
