import { adUnitsAttributesMapper } from 'features/inventory/domain/components/ProductAdUnitsTable/utils'
import { Schema } from '../../schema'
import { Variables } from './types'

export const mapper = (data: Schema): Variables => {
  const {
    adUnitsAttributes,
    desktopAdThreshold,
    desktopRefreshInterval,
    mobileAdThreshold,
    mobileRefreshInterval,
  } = data

  return {
    ...data,
    adUnitsAttributes: adUnitsAttributesMapper<'refresherEnabled'>(
      adUnitsAttributes,
      'refresherEnabled',
    ),
    desktopAdThreshold: desktopAdThreshold / 100,
    desktopRefreshInterval: desktopRefreshInterval * 1000,
    lineItemPriority: data.lineItemPriority.map((priority: string): number => parseInt(priority)),
    mobileAdThreshold: mobileAdThreshold / 100,
    mobileRefreshInterval: mobileRefreshInterval * 1000,
  }
}
