import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { ProductsHeaderMenu } from 'components/Table'
import { Product } from 'features/product'
import { useAbility } from 'providers/casl'
import { ProductSwitchCell } from '../../components/ProductSwitchCell'
import { DomainsForTable } from '../../types'
import { useSwitchDomainsProduct } from '../useSwitchDomainsProduct'
import { UseProductSwitchColumns } from './types'

export const useProductSwitchColumns = ({
  tableFilters,
  tableItemsCount,
}: UseProductSwitchColumns): ColumnDef<DomainsForTable>[] => {
  const ability = useAbility()
  const { t: tDomain } = useTranslation('features/domain')
  const { t: tProduct } = useTranslation('features/product')

  const { update } = useSwitchDomainsProduct()

  const productsSwitches: Product[] = [
    'prebidStack',
    'refresher',
    'viewabilityTools',
    'priceGenius',
    'unfilledRecovery',
  ]

  const column = (product: Product): ColumnDef<DomainsForTable> => {
    const cannotAccessOrUpdateProductToggle: boolean =
      ability.cannot('access', `ProductToggle-${product}`) ||
      ability.cannot('update', `ProductToggle-${product}`)

    const handleToggleProductState = (enabled: boolean): void =>
      void update({ enabledValue: enabled, productType: product, terms: tableFilters })

    return {
      accessorKey: `${product}Enabled`,
      cell: props => (
        <ProductSwitchCell
          {...props}
          disabled={cannotAccessOrUpdateProductToggle}
          product={product}
        />
      ),
      header: tProduct(`product.${product}`),
      meta: {
        disabledHeader: cannotAccessOrUpdateProductToggle,
        headerMenu: (
          <ProductsHeaderMenu
            i18nResource={tDomain}
            productState={{
              disabledToggle: cannotAccessOrUpdateProductToggle || tableItemsCount === 0,
              onToggle: handleToggleProductState,
            }}
          />
        ),
        nonClickable: true,
      },
    }
  }

  return productsSwitches.map((product: Product): ColumnDef<DomainsForTable> => column(product))
}
