import { SvgIcon, Typography } from '@mui/material'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import Dialog from 'components/Dialog'
import { Loading } from 'components/Loading/Loading'
import RequestButton from 'features/product/components/RequestButton'
import { getProductWithConfig } from 'features/product/utils/productsConfig'
import { useRequestProduct } from './hooks/useRequestProduct'
import { ProductDialogProps } from './types'
import { selectContentTranslation } from './utils'

export const ProductDialog = ({
  isOpen,
  setOpen,
  workspaceProduct,
}: ProductDialogProps): JSX.Element => {
  const { t } = useTranslation('features/product')

  const { logo, product, status } = getProductWithConfig(workspaceProduct)
  const productName: string = t(`product.${product}`)

  const { request } = useRequestProduct(workspaceProduct)

  const handleConfirm = (): void => {
    setOpen(false)
    request()
  }

  const Title = (): JSX.Element => (
    <FlexBox axis='x'>
      <Suspense fallback={<Loading size={40} />}>
        <SvgIcon
          component={logo}
          fontSize='large'
          inheritViewBox
          sx={{ mr: 2 }}
        />
      </Suspense>

      {productName}
    </FlexBox>
  )

  const Content = (): JSX.Element => (
    <>
      <Typography sx={{ mb: 1 }}>
        {t(`dialog.${selectContentTranslation(product, status)}.subtitle`, {
          product: productName,
        })}
      </Typography>

      <Typography>
        {t(`dialog.${selectContentTranslation(product, status)}.description`)}
      </Typography>
    </>
  )

  const Actions = (): JSX.Element => (
    <RequestButton
      onClick={handleConfirm}
      workspaceProduct={workspaceProduct}
    />
  )

  return (
    <Dialog
      actions={<Actions />}
      content={<Content />}
      isOpen={isOpen}
      setOpen={setOpen}
      title={<Title />}
    />
  )
}
