import { lazy } from 'react'

import { Logos } from 'types/logos'

const Adagio = lazy(() => import('images/logos/prebidModule/Adagio.svg?react'))
const Adquery = lazy(() => import('images/logos/prebidModule/Adquery QID.svg?react'))
const AMX = lazy(() => import('images/logos/prebidModule/AMX ID.svg?react'))
const ATS = lazy(() => import('images/logos/prebidModule/ATS Analytics.svg?react'))
const Cee = lazy(() => import('images/logos/prebidModule/Cee ID.svg?react'))
const Criteo = lazy(() => import('images/logos/prebidModule/Criteo ID.svg?react'))
const Geoedge = lazy(() => import('images/logos/prebidModule/Geoedge.svg?react'))
const GooglePairID = lazy(() => import('images/logos/prebidModule/Google PAIR ID.svg?react'))
const Greenbids = lazy(() => import('images/logos/prebidModule/Greenbids.svg?react'))
const Id5 = lazy(() => import('images/logos/prebidModule/Id5.svg?react'))
const Just = lazy(() => import('images/logos/prebidModule/Just ID.svg?react'))
const Live = lazy(() => import('images/logos/prebidModule/Live Intent Id.svg?react'))
const Lotame = lazy(() => import('images/logos/prebidModule/Lotame Panorama Id.svg?react'))
const Lupon = lazy(() => import('images/logos/prebidModule/Lupon.svg?react'))
const Quantcast = lazy(() => import('images/logos/prebidModule/Quantcast ID.svg?react'))
const Roxot = lazy(() => import('images/logos/prebidModule/Roxot.svg?react'))
const Shared = lazy(() => import('images/logos/prebidModule/Shared Id.svg?react'))
const Teads = lazy(() => import('images/logos/prebidModule/Teads ID.svg?react'))
const Unified = lazy(() => import('images/logos/prebidModule/Unified Id.svg?react'))
const Weborama = lazy(() => import('images/logos/prebidModule/Weborama.svg?react'))

export const prebidModuleLogos: Logos = {
  'Adagio RTD Provider': Adagio,
  'Adquery QID': Adquery,
  'AMX ID': AMX,
  'ATS Analytics': ATS,
  'Cee ID': Cee,
  'Criteo ID': Criteo,
  Geoedge: Geoedge,
  'Google PAIR ID': GooglePairID,
  'Greenbids Analytics': Greenbids,
  'Greenbids RTD Provider': Greenbids,
  Id5: Id5,
  'Just ID': Just,
  'Live Intent ID': Live,
  'Lotame Panorama ID': Lotame,
  'Lupon Media DAB+': Lupon,
  'Quantcast ID': Quantcast,
  'Roxot Analytics': Roxot,
  'Shared ID': Shared,
  'Teads ID': Teads,
  'Unified ID': Unified,
  Weborama: Weborama,
} as const
