import { mapDTO } from '@twistezo/ts-dto-mapper'
import { isEmpty } from 'lodash'

import { Schema } from '../../../schema'
import { Variables } from './types'

type From = Schema
type To = Variables

export const toDTO = (from: From): To =>
  mapDTO<From, To>({ from }).transform((schema: From): To => {
    const {
      bannerTemplateId,
      duration,
      instreamEnabled,
      jsdelivrEnabled,
      nativeEnabled,
      nativeTemplateId,
      vastUrl,
      workspaceId,
    } = schema

    return {
      bannerTemplateId: isEmpty(bannerTemplateId) ? null : Number(bannerTemplateId),
      duration: duration * 1000,
      instreamEnabled,
      jsdelivrEnabled,
      nativeEnabled,
      nativeTemplateId: isEmpty(nativeTemplateId) ? null : Number(nativeTemplateId),
      vastUrl,
      workspaceId,
    }
  })
