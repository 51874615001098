import { mapDTO } from '@twistezo/ts-dto-mapper'

import {
  MediaTypesDTO,
  MediaTypeViewportAttributeDTO,
} from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MediaTypes, MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { mediaTypeDefaultAttributesFromDTO } from './mediaTypeDefaultAttributes'
import { mediaTypeViewportAttributesFromDTO } from './mediaTypeViewportAttributes'

const SPACE = 2

export const mediaTypesFromDTO = (from: MediaTypesDTO): MediaTypes =>
  mapDTO<MediaTypesDTO, MediaTypes>({ from }).transform((dto: MediaTypesDTO): MediaTypes => {
    const {
      defaultAttribute,
      ignoreViewportAttributes,
      nativeConfiguration,
      videoConfiguration,
      viewportAttributes,
    } = dto

    return {
      ...dto,
      defaultAttribute: mediaTypeDefaultAttributesFromDTO(defaultAttribute),
      kind: ignoreViewportAttributes ? 'default' : 'viewports',
      nativeConfiguration: JSON.stringify(nativeConfiguration, null, SPACE),
      videoConfiguration: JSON.stringify(videoConfiguration, null, SPACE),
      viewportAttributes: viewportAttributes.map(
        (mediaType: MediaTypeViewportAttributeDTO): MediaTypeViewportAttribute =>
          mediaTypeViewportAttributesFromDTO(mediaType),
      ),
    }
  })
