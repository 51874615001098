import _ from 'lodash'

import { AdUnitSizeDTO } from 'features/globalSetup/features/viewport'
import { adUnitSizeToDTO } from 'features/globalSetup/features/viewport/utils'
import { EditSchema } from '../../../schemas/editSchema'
import { MediaTypeViewportsSchema } from '../../../schemas/mediaTypesSchema'
import { MediaTypeAttributeDTO, MediaTypesDTOVariable, Variables } from './types'

export const mapper = (data: EditSchema): Variables => {
  const { divId, mediaTypes, name } = data

  return {
    ..._.omit(data, 'domainId', 'path', 'rawId', 'unfilledRecoveryAdUnit'),
    divId: divId || null,
    mediaTypes: mediaTypesToDTO(mediaTypes),
    name: name || null,
  }
}

export const mediaTypesToDTO = ({
  defaultAttribute,
  kind,
  nativeConfiguration,
  videoConfiguration,
  videoType,
  viewportAttributes,
}: EditSchema['mediaTypes']) => ({
  defaultAttribute: kind === 'default' ? defaultAttributeToDTO(defaultAttribute) : undefined,
  ignoreViewportAttributes: kind === 'default' ? true : false,
  nativeConfiguration: JSON.parse(nativeConfiguration),
  videoConfiguration: JSON.parse(videoConfiguration),
  videoType,
  viewportAttributes:
    kind === 'viewports' ? viewportAttributesToDTO(viewportAttributes) : undefined,
})

const defaultAttributeToDTO = ({
  bannerEnabled,
  bannerSizes,
  mediaTypeId,
  nativeEnabled,
  playerSize,
  videoEnabled,
  viewportId,
}: EditSchema['mediaTypes']['defaultAttribute']): MediaTypeAttributeDTO => ({
  bannerEnabled,
  bannerSizes: bannerSizes.map((size: string): AdUnitSizeDTO => adUnitSizeToDTO(size)),
  id: mediaTypeId,
  nativeEnabled,
  playerSize: playerSize.map((size: string): AdUnitSizeDTO => adUnitSizeToDTO(size)),
  videoEnabled,
  viewportId,
})

export const viewportAttributesToDTO = (
  viewportAttributes: EditSchema['mediaTypes']['viewportAttributes'],
): MediaTypesDTOVariable['viewportAttributes'] =>
  viewportAttributes.map(
    (viewportAttribute: MediaTypeViewportsSchema): MediaTypeAttributeDTO =>
      defaultAttributeToDTO(
        _.pick(viewportAttribute, [
          'bannerEnabled',
          'bannerSizes',
          'mediaTypeId',
          'nativeEnabled',
          'videoEnabled',
          'playerSize',
          'viewportId',
          'updatedAt',
        ]),
      ),
  )
