import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, CodeField, TextField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { SectionHeaderEndAdornment } from './components/SectionHeaderEndAdornment'
import { EditSchema } from './schemas/editSchema'

export const EditForm = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { control, watch } = useFormContext<EditSchema>()
  const { customCodeEnabled } = watch()

  const cannotUpdateDomainCustomCode: boolean = ability.cannot('update', 'DomianFeature-customCode')

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.general.customCodeAttributes.subtitle')}
        title={t('form.general.customCodeAttributes.title')}
        titleEndAdornment={<SectionHeaderEndAdornment />}
      />

      <GapBox />

      {customCodeEnabled && (
        <BoxForm>
          <CodeField
            control={control}
            disabled={cannotUpdateDomainCustomCode}
            language='javascript'
            name='customCodeAttributes.codeBefore'
            title={t('form.general.customCodeAttributes.codeBefore')}
          />

          <CodeField
            control={control}
            disabled={cannotUpdateDomainCustomCode}
            language='javascript'
            name='customCodeAttributes.codeAfter'
            title={t('form.general.customCodeAttributes.codeAfter')}
          />

          <TextField
            control={control}
            disabled={cannotUpdateDomainCustomCode}
            label={t('form.general.customCodeAttributes.comment')}
            minRows={5}
            multiline
            name='customCodeAttributes.comment'
            width='max'
          />
        </BoxForm>
      )}
    </Paper>
  )
}
