import { z } from 'zod'

import { WorkspaceBidderForTable } from '../hooks/useGetBidders/types'
import { mapToFormField } from './mapper'

const workspaceBidderId = z.string()
const workspaceBidderSid = z.string().min(1)

export const schema = z.object({
  workspaceBidderSids: z.record(workspaceBidderId, workspaceBidderSid),
})

export const defaults = (bidders: WorkspaceBidderForTable[]): Schema => ({
  workspaceBidderSids: mapToFormField(bidders),
})

export type WorkspaceBidderSid = z.infer<typeof workspaceBidderSid>
export type Schema = z.infer<typeof schema>
