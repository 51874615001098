import { isNil } from 'lodash'
import { z } from 'zod'

import { Workspace } from 'features/workspace'
import { GamSetupOrder } from 'features/workspace/types/gamSetupOrder'
import { DEFAULT_INSTREAM_DURATION, DEFAULT_VAST_URL } from './constants'

export const schema = z.object({
  bannerEnabled: z.boolean(),
  bannerTemplateId: z.string().optional(),
  duration: z.number().step(1).min(1),
  id: z.string().optional(),
  instreamEnabled: z.boolean(),
  jsdelivrEnabled: z.boolean(),
  nativeEnabled: z.boolean(),
  nativeTemplateId: z.string().optional(),
  vastUrl: z.string(),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  workspaceId: Workspace['id'],
  gamSetupOrder?: GamSetupOrder,
): Schema => {
  const {
    bannerTemplateId,
    duration,
    instreamEnabled,
    jsdelivrEnabled,
    nativeEnabled,
    nativeTemplateId,
    vastUrl,
  } = gamSetupOrder ?? {}

  return {
    bannerEnabled: true,
    bannerTemplateId,
    duration: isNil(duration) ? DEFAULT_INSTREAM_DURATION : duration,
    id: gamSetupOrder?.id,
    instreamEnabled: isNil(instreamEnabled) ? false : instreamEnabled,
    jsdelivrEnabled: isNil(jsdelivrEnabled) ? true : jsdelivrEnabled,
    nativeEnabled: isNil(nativeEnabled) ? false : nativeEnabled,
    nativeTemplateId,
    vastUrl: isNil(vastUrl) ? DEFAULT_VAST_URL : vastUrl,
    workspaceId,
  }
}
