import { Stack, SxProps, TextField as TextFieldRoot, Theme } from '@mui/material'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { TextFieldProps } from './types'

const prepareValue = <T extends FieldValues>(
  fieldValue: string,
  componentValue: TextFieldProps<T>['value'],
): string => {
  if (fieldValue !== undefined) {
    return fieldValue
  } else if (typeof componentValue === 'string' && componentValue !== undefined) {
    return componentValue
  } else return ''
}

export const TextField = <T extends FieldValues>({
  control,
  name,
  tooltip: tooltipProps,
  useTooltipForErrors,
  width,
  ...textFieldProps
}: TextFieldProps<T>): JSX.Element => (
  <Controller
    control={control}
    name={name as FieldPath<T>}
    render={({ field, field: { value }, fieldState: { error } }) => {
      const hasError: boolean = Boolean(error)
      const errorMessage: string = error?.message || ''

      const calculatedWidth: number | string = inputWidth[width ?? 'md']

      const containerSxProps: SxProps<Theme> = {
        height: 'fit-content',
        minWidth: calculatedWidth,
      }

      const inputSxProps: SxProps<Theme> = {
        ...textFieldProps.sx,
        minWidth: calculatedWidth,
        width: calculatedWidth,
      }

      return (
        <Stack
          direction='row'
          sx={containerSxProps}
        >
          <TextFieldRoot
            {...field}
            {...textFieldProps}
            error={hasError}
            helperText={!useTooltipForErrors && errorMessage}
            id={name}
            InputLabelProps={{ shrink: true }}
            sx={inputSxProps}
            value={prepareValue(value, textFieldProps.value)}
          />

          {tooltipProps && <Tooltip {...tooltipProps} />}

          {hasError && useTooltipForErrors && (
            <Tooltip
              content={errorMessage}
              variant='error'
            />
          )}
        </Stack>
      )
    }}
  />
)
