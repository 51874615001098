import { LanOutlined, PeopleAltOutlined } from '@mui/icons-material'
import { TFunction } from 'i18next'
import { generatePath } from 'react-router-dom'

import { ROUTES } from 'routes'
import { SidebarItem } from '../types'

export const configAdmin = (t: TFunction<'features/layout'>): SidebarItem[] => [
  {
    icon: <LanOutlined />,
    title: t('sidebar.employee.workspaces'),
    to: ROUTES.WORKSPACE.LIST,
  },
  {
    icon: <PeopleAltOutlined />,
    selectablePaths: [
      generatePath(ROUTES.USER.LIST, { tab: 'publishers' }),
      generatePath(ROUTES.USER.LIST, { tab: 'employees' }),
    ],
    title: t('sidebar.employee.userManagement'),
    to: generatePath(ROUTES.USER.LIST, { tab: 'publishers' }),
  },
]
