import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { VideoType, videoTypes } from 'features/inventory/ad-unit/types/videoType'

export const useVideoTypeOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.configuration.videoType.options',
  })

  return videoTypes.map(
    (value: VideoType): RadioGroupOption => ({
      label: t(value),
      value,
    }),
  )
}
