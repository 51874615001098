import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { ConsentManagementCommonFields } from 'features/globalSetup'
import { useAbility } from 'providers/casl'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { Schema } from '../../schema'
import { CommonProps } from '../../types'
import { KNOWLEDGE_BASE_URL } from './constants'
import { WorkspaceDefaults } from './WorkspaceDefaults'

export const ConsentManagementSection = ({
  workspacePrebidConfiguration,
}: CommonProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain', {
    keyPrefix: 'form.prebidStack.consentManagement',
  })
  const { t: tC } = useTranslation('common')

  const { control, watch } = useFormContext<Schema>()
  const { customConsentManagementEnabled } = watch()

  const cannotUpdateDomain: boolean = ability.cannot('update', 'Domain')

  return (
    <Paper>
      <SectionHeader
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tC('learnMore'),
              url: KNOWLEDGE_BASE_URL,
            }}
          />
        }
        title={t('title')}
      />

      <GapBox />

      <Stack
        alignItems='flex-start'
        direction='row'
        spacing={2}
      >
        <BoxForm>
          <Switch
            control={control}
            disabled={cannotUpdateDomain}
            label={t('customConsentManagementEnabled.label')}
            labelPlacement='start'
            name='customConsentManagementEnabled'
            tooltip={{
              content: t('customConsentManagementEnabled.tooltip'),
            }}
          />

          {customConsentManagementEnabled && (
            <ConsentManagementCommonFields
              control={control}
              sx={{ ml: CONTAINER_SPACING }}
              watch={watch}
            />
          )}
        </BoxForm>

        {customConsentManagementEnabled && (
          <WorkspaceDefaults {...{ workspacePrebidConfiguration }} />
        )}
      </Stack>
    </Paper>
  )
}
