import i18next from 'i18next'
import { z } from 'zod'

import { outOfPageTypeValues } from 'features/inventory/ad-unit/types/outOfPageType'

export const commonSchema = z.object({
  divId: z.string().optional(),
  domainId: z.string().min(1, i18next.t('common:form.error.nonEmpty')),
  name: z.string().optional(),
  outOfPage: z.boolean(),
  outOfPageType: z.enum(outOfPageTypeValues),
  pageIds: z.array(z.string()),
  path: z.string().min(1),
  rawId: z.number().optional(),
  unfilledRecoveryAdUnit: z.boolean().optional(),
})

export type CommonSchema = z.infer<typeof commonSchema>
