import { useTranslation } from 'react-i18next'

import { RadioGroupOption } from 'components/Form'
import { VideoPlayer, videoPlayers } from 'features/globalSetup/types/videoPlayer'

export const useVideoPlayerOptions = (): RadioGroupOption[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.prebidVideo',
  })

  return videoPlayers.map(
    (value: VideoPlayer): RadioGroupOption => ({
      label: t(`videoPlayer.options.${value}`),
      value,
    }),
  )
}
