import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import Filter from 'components/Filter'
import { useMergeFormData } from 'components/Form'
import { Table, TableHeader } from 'components/Table'
import { SupplyChainContext } from 'features/globalSetup/contexts/SupplyChainContext'
import { DomainSupplyChain } from 'features/globalSetup/types/domainSupplyChain'
import { mapToFormField } from '../../form/mapper'
import { Schema } from '../../form/schema'
import { useColumns } from '../../hooks/useColumns'
import { DescriptionCondtition } from '../DescriptionCondtition'
import { Props } from './types'

export const DomainSidsTable = ({
  data,
  filter: { filters, setFilters },
  pagination,
  sort,
}: Props): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.domainSids',
  })
  const { loading } = useContext(SupplyChainContext)
  const columns = useColumns()

  const TableFilter: JSX.Element = (
    <Filter
      id='schain-domain-sids-filter'
      onChange={setFilters}
      placeholder={t('filter')}
      values={filters}
    />
  )

  useMergeFormData<Schema>({
    data: mapToFormField(data),
    path: 'domainSupplyChainsSids',
  })

  return (
    <>
      <TableHeader
        description={t('description.info')}
        descriptionEndAdornment={<DescriptionCondtition />}
        filter={TableFilter}
        title={t('title')}
      />

      <GapBox />

      <Table<DomainSupplyChain>
        columns={columns}
        data={data}
        loading={loading}
        meta={{ verticalBorders: true }}
        pagination={pagination}
        sort={sort}
      />
    </>
  )
}
