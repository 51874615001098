import { DesktopWindowsOutlined, PhoneIphoneOutlined } from '@mui/icons-material'
import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { NumberField, Switch } from 'components/Form'
import { useAbility } from 'providers/casl'
import { Schema } from '../../schema'
import { TableRowData } from './types'

export const useColumns = (): ColumnDef<TableRowData>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain', { keyPrefix: 'form.refresher.basicSettings' })
  const { control } = useFormContext<Schema>()

  const cannotUpdateDomain: boolean = ability.cannot('update', 'Domain')

  return [
    {
      accessorKey: 'device',
      cell: ({
        row: {
          original: { device },
        },
      }) => (
        <FlexBox
          axis='x'
          gap={1}
        >
          {device === 'desktop' ? (
            <DesktopWindowsOutlined fontSize='inherit' />
          ) : (
            <PhoneIphoneOutlined fontSize='inherit' />
          )}
          {t(`device.${device}`)}
        </FlexBox>
      ),
      header: '',
      meta: { isPinned: true },
    },

    {
      accessorKey: 'refreshInterval',
      cell: ({
        getValue,
        row: {
          original: { device },
        },
      }) => (
        <NumberField
          control={control}
          disabled={cannotUpdateDomain}
          name={`${device}RefreshInterval`}
          value={getValue()}
        />
      ),

      header: t('columns.refreshInterval.label'),
      meta: {
        centered: true,
        tooltip: { content: t('columns.refreshInterval.tooltip') },
      },
    },

    {
      accessorKey: 'adThreshold',
      cell: ({
        getValue,
        row: {
          original: { device },
        },
      }) => (
        <NumberField
          control={control}
          disabled={cannotUpdateDomain}
          name={`${device}AdThreshold`}
          value={getValue()}
        />
      ),
      header: t('columns.adThreshold.label'),
      meta: {
        centered: true,
        tooltip: { content: t('columns.adThreshold.tooltip') },
      },
    },

    {
      accessorKey: 'monitorUserScrollEnabled',
      cell: ({
        getValue,
        row: {
          original: { device },
        },
      }) => (
        <Switch
          control={control}
          disabled={cannotUpdateDomain}
          name={`${device}MonitorUserScrollEnabled`}
          value={getValue()}
        />
      ),
      header: t('columns.monitorUserScrollEnabled.label'),
      meta: {
        centered: true,
        tooltip: { content: t('columns.monitorUserScrollEnabled.tooltip') },
      },
    },
  ]
}
