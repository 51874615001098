import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { BoxWithLogo } from 'components/Box'
import { LastModifiedCell } from 'components/Table'
import { bidderLogos } from 'constants/bidderLogos'
import { WorkspaceBidderForTable } from '../../hooks/useGetBidders/types'
import { SidInputCell } from './components/SidInputCell'

export const useColumns = (): ColumnDef<WorkspaceBidderForTable>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'supplyChain.bidderSids.headers',
  })

  return [
    {
      accessorKey: 'name',
      cell: ({
        row: {
          original: { name },
        },
      }) => <BoxWithLogo logo={{ size: 'small', svg: bidderLogos[name] }}>{name}</BoxWithLogo>,
      header: t('name'),
      meta: { isPinned: true },
    },
    {
      accessorKey: 'code',
      header: t('code'),
      meta: { isPinned: true },
    },
    {
      accessorKey: 'sid',
      cell: context => <SidInputCell {...context} />,
      header: t('sid.label'),
      meta: { tooltip: { content: t('sid.tooltip') } },
    },
    {
      accessorKey: 'updatedAt',
      cell: context => <LastModifiedCell {...context} />,
      header: t('modified'),
    },
  ]
}
