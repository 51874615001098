import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralFormEdit } from 'features/inventory/page/forms/GeneralForm'
import PrebidStackForm from 'features/inventory/page/forms/PrebidStackForm'
import { useAbility } from 'providers/casl'
import { generateSelector } from 'providers/product-fruits/utils'
import { ProductTab } from 'types/productTab'

export const useProductTabs = (): Tab<ProductTab>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/page')
  const { t: tProduct } = useTranslation('features/product')

  const cannotAccess = ability.cannot('access', 'PagePage-edit')

  return [
    {
      disabled: cannotAccess,
      element: 'general',
      label: t('edit.tabs.general'),
      page: <GeneralFormEdit />,
      productFruitsSelector: generateSelector({ step: 1, tour: 2 }),
    },
    {
      authorized: 'ProductForm-prebidStack',
      disabled: cannotAccess || !ability.can('access', 'ProductForm-prebidStack'),
      element: 'prebid-stack',
      label: tProduct('product.prebidStack'),
      page: <PrebidStackForm />,
    },
  ]
}
