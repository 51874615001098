import { z } from 'zod'

import { validateAdUnitSizes } from '../../utils'

export const viewportSchema = z.object({
  bannerEnabled: z.boolean(),
  bannerSizes: z.string().array().superRefine(validateAdUnitSizes),
  minimumWidth: z.number().min(0),
  name: z.string().min(1),
  nativeEnabled: z.boolean(),
  playerSize: z.string().array().superRefine(validateAdUnitSizes),
  videoEnabled: z.boolean(),
  viewportId: z.string().optional(),
})

export const schema = z.object({
  viewports: viewportSchema.array(),
})

export type Schema = z.infer<typeof schema>
export type ViewportSchema = z.infer<typeof viewportSchema>

export const defaultViewportSchema: ViewportSchema = {
  bannerEnabled: false,
  bannerSizes: [],
  minimumWidth: 0,
  name: '',
  nativeEnabled: false,
  playerSize: [],
  videoEnabled: false,
  viewportId: '',
}

export const defaultSchema = (): Schema => ({ viewports: [] })
