import { User } from 'features/user'
import { Can } from '../Can'
import { onAdUnitCreateUpdateDelete, onAdUnitMediaTypesCUD } from './features/adUnit'
import {
  onDomainAdvancedSettingsUpdate,
  onDomainBulkManagementCreate,
  onDomainCreateUpdateDelete,
  onDomainCustomCodeUpdate,
} from './features/domain'
import { onFinanceAccess } from './features/finance'
import { onGlobalSetupAmazonUpdate } from './features/globalSetup/amazon'
import {
  onGlobalSetupWorkspaceUpdate,
  onGlobalSetupWorkspaceUserACUD,
} from './features/globalSetup/common'
import { onGlobalSetupGoogleAdManagerConnectionUpdate } from './features/globalSetup/googleAccountManagerConnection'
import {
  onGlobalSetupBiddersBidParamsCommonUpdate,
  onGlobalSetupBiddersBidParamsOwnUpdate,
  onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate,
  onGlobalSetupBiddersCUD,
  onGlobalSetupBiddersOwnUpdate,
  onGlobalSetupBiddersThirdPartyUpdate,
  onGlobalSetupBiddersYieldbirdUpdate,
  onGlobalSetupPrebidBiddersAddaccess,
  onGlobalSetupPrebidDefaultsUpdate,
  onGlobalSetupPrebidModuleCUD,
  onGlobalSetupPrebidModulesGeneralParamsOwnUpdate,
  onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate,
  onGlobalSetupProtectionACUD,
} from './features/globalSetup/prebid'
import {
  aonGlobalSetupSupplyChainCUD,
  onGlobalSetupSupplyChainAddAccess,
} from './features/globalSetup/supplyChain'
import { onViewportCUD } from './features/globalSetup/viewport'
import { onPageAddAccess, onPageCreateUpdateDelete } from './features/page'
import { onProductUpdate } from './features/product'

export const userByWorkspaceRole = (user: User, can: Can) => {
  switch (user.currentWorkspaceRole) {
    case 'owner': {
      aonGlobalSetupSupplyChainCUD(can)
      onAdUnitCreateUpdateDelete(can)
      onAdUnitMediaTypesCUD(can)
      onDomainAdvancedSettingsUpdate(can)
      onDomainBulkManagementCreate(can)
      onDomainCreateUpdateDelete(can)
      onDomainCustomCodeUpdate(can)
      onFinanceAccess(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBiddersBidParamsCommonUpdate(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupBiddersCUD(can)
      onGlobalSetupBiddersOwnUpdate(can)
      onGlobalSetupBiddersThirdPartyUpdate(can)
      onGlobalSetupBiddersYieldbirdUpdate(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAddaccess(can)
      onGlobalSetupPrebidDefaultsUpdate(can)
      onGlobalSetupPrebidModuleCUD(can)
      onGlobalSetupPrebidModulesGeneralParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupProtectionACUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupWorkspaceUpdate(can)
      onGlobalSetupWorkspaceUserACUD(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onProductUpdate(can)
      onViewportCUD(can)
      break
    }

    case 'editor': {
      aonGlobalSetupSupplyChainCUD(can)
      onAdUnitCreateUpdateDelete(can)
      onAdUnitMediaTypesCUD(can)
      onDomainAdvancedSettingsUpdate(can)
      onDomainBulkManagementCreate(can)
      onDomainCreateUpdateDelete(can)
      onDomainCustomCodeUpdate(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupBiddersBidParamsCommonUpdate(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupBiddersCUD(can)
      onGlobalSetupBiddersOwnUpdate(can)
      onGlobalSetupBiddersThirdPartyUpdate(can)
      onGlobalSetupBiddersYieldbirdUpdate(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAddaccess(can)
      onGlobalSetupPrebidDefaultsUpdate(can)
      onGlobalSetupPrebidModuleCUD(can)
      onGlobalSetupPrebidModulesGeneralParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupProtectionACUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupWorkspaceUpdate(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onProductUpdate(can)
      onViewportCUD(can)
      break
    }

    case 'viewer': {
      break
    }
  }
}
