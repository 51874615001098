import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { RelatedList, RelatedListItem, RelatedSettings } from 'components/RelatedSettings'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { ROUTES } from 'routes'
import { CommonProps } from '../../../types'

export const WorkspaceDefaults = ({
  workspacePrebidConfiguration: {
    gdprTcfEnabled,
    gdprTcfTimeout,
    gppControlUsNat,
    gppControlUsStates,
    gppEnabled,
    gppTimeout,
    usPrivacyCcpaEnabled,
    usPrivacyCcpaTimeout,
  },
}: CommonProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'relatedSettings',
  })
  const { workspaceId } = useWorkspaceParam()

  const GdprTcFTimeout = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('consentManagement.gdprTcfEnabled')}
        url={generatePath(ROUTES.GLOBAL_SETUP.PREBID.DEFAULTS.ROOT, { workspaceId })}
        value={t(`settingEnabled.${gdprTcfEnabled}`)}
      />

      {gdprTcfEnabled && (
        <RelatedList>
          <RelatedListItem
            content={t('consentManagement.gdprTcfTimeout')}
            value={gdprTcfTimeout}
          />
        </RelatedList>
      )}
    </RelatedList>
  )

  const UsPrivacyCcpaTimeoutEnabled = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('consentManagement.usPrivacyCcpaEnabled')}
        value={t(`settingEnabled.${usPrivacyCcpaEnabled}`)}
      />

      {usPrivacyCcpaEnabled && (
        <RelatedList>
          <RelatedListItem
            content={t('consentManagement.usPrivacyCcpaTimeout')}
            value={usPrivacyCcpaTimeout}
          />
        </RelatedList>
      )}
    </RelatedList>
  )

  const GppTimeoutEnabled = (): JSX.Element => (
    <RelatedList>
      <RelatedListItem
        content={t('consentManagement.gppEnabled')}
        value={t(`settingEnabled.${gppEnabled}`)}
      />

      {gppEnabled && (
        <RelatedList>
          <RelatedListItem
            content={t('consentManagement.gppTimeout')}
            value={gppTimeout}
          />
          <RelatedListItem
            content={t('consentManagement.gppControlUsNat')}
            value={t(`settingEnabled.${gppControlUsNat}`)}
          />
          <RelatedListItem
            content={t('consentManagement.gppControlUsStates')}
            value={t(`settingEnabled.${gppControlUsStates}`)}
          />
        </RelatedList>
      )}
    </RelatedList>
  )

  return (
    <RelatedSettings title={t('consentManagement.title')}>
      <GdprTcFTimeout />
      <UsPrivacyCcpaTimeoutEnabled />
      <GppTimeoutEnabled />
    </RelatedSettings>
  )
}
