import { Can } from 'providers/casl'

export const onGlobalSetupSupplyChainAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-supplyChain-list')
  can('access', 'GlobalSetupPage-supplyChain-edit')
}

export const onGlobalSetupSupplyChainAddAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-supplyChain-add')
}

export const aonGlobalSetupSupplyChainCUD = (can: Can) => {
  can('create', 'GlobalSetupFeature-supplyChain')
  can('update', 'GlobalSetupFeature-supplyChain')
  can('delete', 'GlobalSetupFeature-supplyChain')
}
