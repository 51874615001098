import { Button, FormLabel } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { BoxForm, Select } from 'components/Form'
import { Table } from 'components/Table'
import { useAbility } from 'providers/casl'
import { CONTAINER_SPACING } from 'providers/material-ui/theme/constants'
import { EditSchema } from '../../../../schemas/editSchema'
import { useMediaTypeViewports } from '../../hooks/useMediaTypeViewports'
import { useColumns } from './useColumns'

export const ViewportsTable = (): JSX.Element => {
  const { t } = useTranslation(['features/adUnit', 'common'])
  const ability = useAbility()

  const { control } = useFormContext<EditSchema>()
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'mediaTypes.viewportAttributes',
  })
  const { getNewMediaTypeData, options } = useMediaTypeViewports()

  const columns: ColumnDef<FieldArrayWithId<EditSchema>>[] = useColumns({ remove })
  const addDisabled = options.length === 0
  const cannotUpdateAdUnit = ability.cannot('update', 'AdUnit')

  const AddButton = (): JSX.Element => (
    <Button
      data-cy='viewport-add'
      disabled={cannotUpdateAdUnit || addDisabled}
      onClick={(): void => {
        const [index, schema] = getNewMediaTypeData()

        insert(index, schema)
      }}
      variant='contained'
    >
      {t('common:form.action.add')}
    </Button>
  )

  return (
    <>
      <BoxForm pb={CONTAINER_SPACING}>
        <FormLabel>{t('form.general.mediaTypes.viewportList.add.title')}</FormLabel>

        <FlexBox
          axis='x'
          gap={2}
        >
          <Select
            control={control}
            disabled={cannotUpdateAdUnit || addDisabled}
            label={t('form.general.mediaTypes.viewportList.add.label')}
            name='mediaTypes.newViewportId'
            options={options}
          />
          <AddButton />
        </FlexBox>
      </BoxForm>

      <Table<FieldArrayWithId<EditSchema>>
        columns={columns}
        data={fields}
      />
    </>
  )
}
