import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, Slider, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useAbility } from 'providers/casl'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import { useTrafficPercentMarks } from './hooks/useTrafficPercentMarks'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation('features/domain')
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  const trafficPercentMarks = useTrafficPercentMarks()

  const cannotUpdateDomain: boolean = ability.cannot('update', 'Domain')
  const cannotUpdatePriceGenius: boolean = ability.cannot('update', 'ProductToggle-priceGenius')

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.priceGenius.enabled.description')}
          title={t('form.priceGenius.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              disabled={cannotUpdateDomain || cannotUpdatePriceGenius}
              name='priceGeniusEnabled'
            />
          }
        />

        <GapBox />

        <BoxForm>
          <Slider
            control={control}
            disabled={cannotUpdateDomain}
            label={t('form.priceGenius.trafficPercent.title')}
            marks={trafficPercentMarks}
            name='trafficPercent'
            tooltip={{ content: t('form.priceGenius.trafficPercent.tooltip') }}
            withInput
          />
        </BoxForm>
      </Paper>

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={domain!.id} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            product='priceGenius'
          />
        )}
      />
    </>
  )
}
