import { Box, Collapse } from '@mui/material'
import { kebabCase } from 'change-case'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { EmptyObject } from 'type-fest'

import { ListItemButton, ListItemButtonProps } from 'components/List'
import { GLOBAL_BORDER_RADIUS } from 'providers/material-ui/theme/constants'
import { COLLAPSED_Y_DEFAULT } from './constants'
import { CollapsedY, RenderItemFn, SidebarItem, UseSidebar, UseSidebarProps } from './types'

export const useSidebar = ({ collapsedX, onCollapsedX }: UseSidebarProps): UseSidebar => {
  const [collapsedY, setCollapsedY] = useState<CollapsedY>(COLLAPSED_Y_DEFAULT)

  useEffect(() => {
    if (collapsedX === true) {
      setCollapsedY(COLLAPSED_Y_DEFAULT)
    }
  }, [collapsedX])

  const isSidebarItemEmpty = (item: SidebarItem): item is EmptyObject =>
    _.isEmpty(item as EmptyObject)

  const EmptyItem = (): JSX.Element => <Box sx={{ flexGrow: 1 }} />

  const GroupItem = ({
    item,
  }: {
    item: Required<Pick<Exclude<SidebarItem, EmptyObject>, 'group'>> & SidebarItem
  }): JSX.Element => {
    if (isSidebarItemEmpty(item)) {
      return <EmptyItem />
    }

    const { group, icon, productFruitsSelector, title } = item

    const handleOnCollapseY = (): void => {
      if (collapsedX) {
        onCollapsedX(false)
      }

      setCollapsedY({ ...collapsedY, [group]: !collapsedY[group] })
    }

    return (
      <NavItem
        collapsedX={collapsedX}
        collapsedY={collapsedY[group]}
        icon={icon}
        onCollapseY={handleOnCollapseY}
        productFruitsSelector={productFruitsSelector}
        text={title}
      />
    )
  }

  const SingleItem = ({ item }: { item: SidebarItem }): JSX.Element => {
    if (isSidebarItemEmpty(item)) {
      return <EmptyItem />
    }

    const {
      color,
      endAdornment,
      icon,
      id,
      isSelectable,
      marginBottom,
      newTab,
      onClick,
      productFruitsSelector,
      selectablePaths,
      shifted,
      title,
      to,
    } = item

    return (
      <Box sx={{ ml: shifted ? 2 : 0 }}>
        <NavItem
          endAdornment={endAdornment}
          icon={icon}
          id={id}
          isSelectable={isSelectable ?? true}
          newTab={newTab}
          onClick={onClick}
          productFruitsSelector={productFruitsSelector}
          selectablePaths={selectablePaths}
          sx={{
            ...(color ? { backgroundColor: color.main } : {}),
            ...(marginBottom ? { mb: 2 } : {}),
          }}
          text={title}
          to={to}
        />
      </Box>
    )
  }

  const NavItem = ({ id, productFruitsSelector, ...props }: ListItemButtonProps): JSX.Element => (
    <ListItemButton
      {...props}
      collapsedX={collapsedX}
      data-cy={`nav-item-${kebabCase(id || props.text)}`}
      data-product-fruits={productFruitsSelector}
      sx={{
        ...props.sx,
        borderRadius: `${GLOBAL_BORDER_RADIUS}px`,
      }}
    />
  )

  const renderItem: RenderItemFn = (item, index) => {
    if (isSidebarItemEmpty(item)) {
      return <EmptyItem key={index} />
    }

    const { group, hidden, subItems, title } = item
    const isHidden =
      hidden ||
      (group && subItems?.every(subItem => !isSidebarItemEmpty(subItem) && subItem.hidden))

    if (isHidden) return <div key={title}></div>

    return (
      <Box key={title}>
        {group ? (
          <>
            <GroupItem item={{ ...item, group }} />

            {subItems?.length && (
              <Collapse in={collapsedY[group]}>
                {subItems.map(
                  (subItem: SidebarItem): JSX.Element =>
                    isSidebarItemEmpty(subItem) ? (
                      <EmptyItem key={index} />
                    ) : (
                      <div key={subItem.title}>{renderSubItem(subItem, index)}</div>
                    ),
                )}
              </Collapse>
            )}
          </>
        ) : (
          <SingleItem item={item} />
        )}
      </Box>
    )
  }

  const renderSubItem = (item: SidebarItem, index: number): JSX.Element => {
    if (isSidebarItemEmpty(item)) {
      return <EmptyItem key={index} />
    }

    const { group, hidden, title } = item

    if (hidden) return <></>

    return (
      <>
        {group ? (
          renderItem(item, -1)
        ) : (
          <SingleItem
            item={item}
            key={title}
          />
        )}
      </>
    )
  }

  return { renderItem }
}
