import { mapDTO } from '@twistezo/ts-dto-mapper'

import { SortInput } from 'components/Table'
import { WorkspaceBidderForTable, WorkspaceBidderForTableSortBy } from '../types'
import { WorkspaceBidderForTableDTO, WorkspaceBidderForTableSortByDTO } from './types'

export const fromDTO = (from: WorkspaceBidderForTableDTO) =>
  mapDTO<WorkspaceBidderForTableDTO, WorkspaceBidderForTable>({ from }).transform(
    (dto: WorkspaceBidderForTableDTO): WorkspaceBidderForTable => {
      const {
        bidder: { name },
        bidderCode,
        bidderSid,
        id,
        updatedAt,
      } = dto

      return {
        code: bidderCode,
        id,
        name,
        sid: bidderSid,
        updatedAt: new Date(updatedAt),
      }
    },
  )

export const sortToDTO = (
  sort: SortInput<WorkspaceBidderForTableSortBy>,
): SortInput<WorkspaceBidderForTableSortByDTO> => {
  const { by } = sort

  switch (by) {
    case 'code':
      return { ...sort, by: 'bidderCode' }
    case 'name':
      return { ...sort, by: 'bidderName' }
    case 'sid':
      return { ...sort, by: 'bidderSid' }
    default:
      return { ...sort, by }
  }
}
