import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { InventoryConnectionContext } from 'features/globalSetup/forms/Prebid/Modules/contexts/InventoryConnectionContext'
import {
  DomainsTable,
  InventoryConnectionForm,
} from 'features/globalSetup/forms/Prebid/Modules/InventoryConnectionForm'
import { PrebidModule } from 'features/globalSetup/types/prebidModule'
import { useAuthUser } from 'features/user'

export const InventoryConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.vendorSpecific.edit.inventoryConnection',
  })
  const {
    helpers: { isEmployee },
  } = useAuthUser()
  const { workspacePrebidModule } = useContext(PrebidModulesContext)
  const [filters, setFilters] = useState<FilterValue[]>([])

  if (!workspacePrebidModule) {
    throw Error('Workspace Prebid Module is not defined')
  }
  const prebidModuleName: PrebidModule['name'] = workspacePrebidModule.prebidModule.name

  const AnalyticsDomainsTableFilter = (
    <Filter
      id='vendorSpecificDomainsTableFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        description={
          isEmployee
            ? t('header.subtitle.employee', { prebidModuleName })
            : t('header.subtitle.publisher', { prebidModuleName })
        }
        filter={AnalyticsDomainsTableFilter}
        title={t('header.title')}
      />

      <GapBox />

      <InventoryConnectionContext.Provider value={{ moduleType: 'vendorSpecific' }}>
        <InventoryConnectionForm>
          <DomainsTable filters={filters} />
        </InventoryConnectionForm>
      </InventoryConnectionContext.Provider>
    </Paper>
  )
}
