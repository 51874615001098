import { Can } from 'providers/casl'

export const onDomainAccess = (can: Can) => {
  can('access', 'DomainPage-list')
  can('access', 'DomainPage-add')
  can('access', 'DomainPage-edit')
}

export const onDomainCreateUpdateDelete = (can: Can) => {
  can('create', 'Domain')
  can('update', 'Domain')
  can('delete', 'Domain')
}

export const onDomainCustomCodeUpdate = (can: Can) => {
  can('update', 'DomianFeature-customCode')
}

export const onDomainBulkManagementCreate = (can: Can) => {
  can('create', 'DomainFeature-bulkManagement')
}

export const onDomainOverridenByYieldbirdRead = (can: Can) => {
  can('read', 'DomainField-overriddenByYieldbird')
}

export const onDomainOverridenByYieldbirdUpdate = (can: Can) => {
  can('update', 'DomainField-overriddenByYieldbird')
}

export const onDomainAdvancedSettingsUpdate = (can: Can) => {
  can('update', 'DomianFeature-advancedSettings')
}
