import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { InventoryAmazonUamTamSection } from 'features/globalSetup/features/amazon'
import { useAbility } from 'providers/casl'
import { AddEditAdUnitContext } from '../../contexts/AddEditAdUnitContext'
import { Schema } from './schema'
import { BiddersSection } from './sections/BiddersSection'

export const Form = (): JSX.Element => {
  const { t } = useTranslation('features/adUnit')
  const { adUnit } = useContext(AddEditAdUnitContext)
  const { control } = useFormContext<Schema>()
  const ability = useAbility()
  const cannotUpdateAdUnit = ability.cannot('update', 'AdUnit')

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }
  const { adUnitAmazonEnabled } = adUnit

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.prebidStack.enabled.description')}
          title={t('form.prebidStack.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              disabled={cannotUpdateAdUnit}
              name='prebidStackEnabled'
            />
          }
        />
      </Paper>

      <BiddersSection />

      <InventoryAmazonUamTamSection<Schema>
        amazonState={adUnitAmazonEnabled}
        feature='adUnit'
      />
    </>
  )
}
