import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, RadioGroupOption } from 'components/Form'
import { SelectCheckboxes } from 'components/Form/SelectCheckboxes'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { Schema } from '../../schema'
import { useVideoPlayerOptions } from './useVideoPlayerOptions'

export const PrebidVideoSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.defaults.prebidVideo',
  })
  const { t: tc } = useTranslation('common')
  const { control } = useFormContext<Schema>()

  const ability = useAbility()
  const cannotUpdate: boolean = ability.cannot('update', 'GlobalSetupFeature-prebidDefaults')

  const videoPlayerOptions: RadioGroupOption[] = useVideoPlayerOptions()

  return (
    <Paper>
      <SectionHeader
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: tc('learnMore'),
              url: KNOWLEDGE_BASE_URL.PREBID_VIDEO.SUBTITLE,
            }}
          />
        }
        title={t('title')}
      />

      <GapBox />

      <BoxForm>
        <SelectCheckboxes
          control={control}
          disabled={cannotUpdate}
          label={t('videoPlayer.label')}
          name='videoPlayer'
          options={videoPlayerOptions}
          tooltip={{
            content: [
              t('videoPlayer.tooltip.header'),
              t('videoPlayer.tooltip.paragraph1'),
              t('videoPlayer.tooltip.paragraph2'),
              t('videoPlayer.tooltip.paragraph3'),
            ],
            link: {
              title: t('videoPlayer.tooltip.link'),
              url: KNOWLEDGE_BASE_URL.PREBID_VIDEO.INPUT,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}
