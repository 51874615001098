import { gql, TypedDocumentNode } from '@apollo/client'

import { Data, Variables } from './types'

export const GET_WORKSPACE_PREBID_CONFIGURATION: TypedDocumentNode<Data, Variables> = gql`
  query getWorkspacePrebidConfiguration($workspaceId: ID!) {
    workspacePrebidConfiguration(workspaceId: $workspaceId) {
      auctionDelay
      gdprTcfEnabled
      gdprTcfTimeout
      gppControlUsNat
      gppControlUsStates
      gppEnabled
      gppTimeout
      id
      monetizeNonConsentTraffic
      prebidTimeout
      s2sTimeoutMultiplier
      usPrivacyCcpaEnabled
      usPrivacyCcpaTimeout
      videoPlayer
    }
  }
`
