import { Can } from 'providers/casl'

export const onPerformanceAccess = (can: Can) => {
  can('access', 'PerformancePage')
}

export const onPerformanceDataSamplingReadUpdate = (can: Can) => {
  can('read', 'Performance-dataSampling')
  can('update', 'Performance-dataSampling')
}

export const onPerformanceAdminOptionReadUpdate = (can: Can) => {
  can('read', 'Performance-adminOption')
  can('update', 'Performance-adminOption')
}
