import { Location, To } from 'react-router-dom'

import { ListItemButtonProps } from './types'

const currentLocationIncludes = (to: To, location: Location): boolean => {
  if (typeof to !== 'string') return false

  return location.pathname.includes(to)
}

export const isSelected = ({
  isSelectable,
  location,
  selectablePaths,
  to,
}: { location: Location } & Pick<
  ListItemButtonProps,
  'isSelectable' | 'selectablePaths' | 'to'
>): boolean => {
  if (
    isSelectable === undefined ||
    isSelectable === false ||
    (to === undefined && selectablePaths === undefined)
  )
    return false

  if (selectablePaths !== undefined) {
    return selectablePaths.some((to: To) => currentLocationIncludes(to, location))
  } else if (to !== undefined) {
    return currentLocationIncludes(to, location)
  } else return false
}
