import { z } from 'zod'

import { viewportSchema } from 'features/globalSetup/features/viewport'
import { mediaTypesKindValues } from 'features/inventory/ad-unit/types/mediaTypes'
import { videoTypes } from 'features/inventory/ad-unit/types/videoType'
import { validateJSONSchema } from 'utils/json'

const mediaTypeSchema = z
  .object({
    mediaTypeId: z.string().optional(),
    updatedAt: z.date(),
  })
  .merge(
    viewportSchema.pick({
      bannerEnabled: true,
      bannerSizes: true,
      nativeEnabled: true,
      playerSize: true,
      videoEnabled: true,
      viewportId: true,
    }),
  )

const mediaTypeViewportsSchema = mediaTypeSchema.merge(z.object({ viewport: viewportSchema }))

export const mediaTypesSchema = z.object({
  defaultAttribute: mediaTypeSchema,
  kind: z.enum(mediaTypesKindValues),
  nativeConfiguration: z.string().superRefine(validateJSONSchema),
  newViewportId: z.string().optional(),
  videoConfiguration: z.string().superRefine(validateJSONSchema),
  videoType: z.enum(videoTypes),
  viewportAttributes: mediaTypeViewportsSchema.array(),
})

export type MediaTypeViewportsSchema = z.infer<typeof mediaTypeViewportsSchema>
export type MediaTypeSchema = z.infer<typeof mediaTypeSchema>
export type MediaTypesSchema = z.infer<typeof mediaTypesSchema>
