import { Box, SvgIcon } from '@mui/material'
import { Suspense } from 'react'

import { FlexBox } from 'components/Box'
import Loading from 'components/Loading'
import { BoxWithLogoProps, Logo } from './types'

export const BoxWithLogo = ({ children, logo }: BoxWithLogoProps) => {
  const Logo = ({ logo }: { logo: Logo }): JSX.Element => {
    const { size, svg } = logo

    if (!svg) {
      return <></>
    }

    const fontSize: number = size === 'small' ? 20 : 30

    return (
      <Box sx={{ mr: 1 }}>
        <Suspense fallback={<Loading size={fontSize} />}>
          <SvgIcon
            component={svg}
            inheritViewBox
            sx={{ fontSize }}
          />
        </Suspense>
      </Box>
    )
  }

  return (
    <FlexBox axis='x'>
      <Logo logo={logo} />
      {children}
    </FlexBox>
  )
}
