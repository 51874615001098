import { Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { StackForm } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import Tooltip from 'components/Tooltip'
import { useAuthUser } from 'features/user'
import { inputWidth } from 'providers/material-ui/theme/constants'

export const GoogleAdManagerIdSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.networkCode',
  })

  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw Error('Workspace should be accessible')
  }

  const { networkCode, primaryCurrency } = currentWorkspace

  const PrimaryCurrencyField = (): JSX.Element => (
    <Stack direction='row'>
      <TextField
        disabled
        id='primaryCurrency'
        label={t('primaryCurrency.label')}
        sx={{
          minWidth: inputWidth['sm'],
          width: inputWidth['sm'],
        }}
        value={primaryCurrency ?? t('primaryCurrency.notAvailable')}
      />
      <Tooltip content={t('primaryCurrency.tooltip')} />
    </Stack>
  )

  return (
    <Paper data-cy='google-ad-manager-id-section'>
      <SectionHeader
        subtitle={t('header.description')}
        title={t('header.title')}
      />

      <GapBox />

      <StackForm>
        <TextField
          disabled
          id='networkCode'
          label={t('networkCode.label')}
          value={networkCode}
        />

        <PrimaryCurrencyField />
      </StackForm>
    </Paper>
  )
}
