import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { ActionsCell } from './components/ActionsCell'
import { SupplyChainForTable } from './hooks/useGetSupplyChains/types'

export const useColumns = (): ColumnDef<SupplyChainForTable>[] => {
  const { t } = useTranslation(['features/globalSetup'])

  return [
    {
      accessorKey: 'name',
      header: t('supplyChain.list.header.name.label'),
      meta: { tooltip: { content: t('supplyChain.list.header.name.tooltip') } },
    },
    {
      accessorKey: 'asi',
      header: t('supplyChain.list.header.asi.label'),
      meta: { tooltip: { content: t('supplyChain.list.header.asi.tooltip') } },
    },
    {
      accessorKey: 'defaultSid',
      header: t('supplyChain.list.header.sid.label'),
      meta: { tooltip: { content: t('supplyChain.list.header.sid.tooltip') } },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('supplyChain.list.header.modified'),
    },
    {
      accessorKey: 'actions',
      cell: ({ row }) => <ActionsCell {...row.original} />,
      header: t('supplyChain.list.header.actions'),
      meta: { nonClickable: true },
      size: COLUMN_SIZE.SM,
    },
  ]
}
