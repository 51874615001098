import { Can } from 'providers/casl'

export const onUserAccess = (can: Can) => {
  can('access', 'UserPage-list')
}

export const onUserPageEmployeeACUD = (can: Can) => {
  can('access', 'UserPage-addEmployee')
  can('create', 'Employee')
  can('access', 'UserPage-editEmployee')
  can('update', 'Employee')
  can('delete', 'Employee')
}

export const onUserPagePublisherACUD = (can: Can) => {
  can('access', 'UserPage-addPublisher')
  can('create', 'Publisher')
  can('access', 'UserPage-editPublisher')
  can('update', 'Publisher')
  can('delete', 'Publisher')
}
