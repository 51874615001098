import { authorizeGroupRole, User } from 'features/user'
import { UserGroup } from 'features/user/types/UserGroupRoles'
import { Can } from '../Can'
import { onAdUnitAccess, onAdUnitCreateUpdateDelete, onAdUnitFieldsRead } from './features/adUnit'
import { onDomainAccess } from './features/domain'
import { onFinanceAccess, onFinanceAdminOptionReadUpdate } from './features/finance'
import { onGlobalSetupAmazonAccess } from './features/globalSetup/amazon'
import {
  onGlobalSetupAccess,
  onGlobalSetupPriceGeniusRead,
  onGlobalSetupWorkspaceUpdate,
} from './features/globalSetup/common'
import { onGlobalSetupGoogleAdManagerConnectionAccess } from './features/globalSetup/googleAccountManagerConnection'
import {
  onGlobalSetupPrebidBiddersAccess,
  onGlobalSetupPrebidDefaultsAccess,
  onGlobalSetupPrebidModuleAccess,
  onGlobalSetupPrebidModulesInventoryConnectionParamsOwnRead,
  onGlobalSetupPrebidProtectionAccess,
} from './features/globalSetup/prebid'
import { onGlobalSetupSupplyChainAccess } from './features/globalSetup/supplyChain'
import { onLayoutAccess } from './features/layout'
import { onPageAccess } from './features/page'
import { onPerformanceAccess, onPerformanceAdminOptionReadUpdate } from './features/performance'
import { onPortalAccess } from './features/portal'
import { onProductAccess, onProductStatusReadUpdate, onProductUpdate } from './features/product'
import { onTagAccess } from './features/tag'
import { onUserAccess } from './features/user'
import { onWorkspaceAccess } from './features/workspace'

export const userByGroup = (user: User, can: Can) => {
  const userBelongsToGroup = (userGroup: UserGroup) => authorizeGroupRole(userGroup, user.role)

  if (userBelongsToGroup('everyone')) {
    onAdUnitAccess(can)
    onDomainAccess(can)
    onGlobalSetupAccess(can)
    onGlobalSetupAmazonAccess(can)
    onGlobalSetupGoogleAdManagerConnectionAccess(can)
    onGlobalSetupPrebidBiddersAccess(can)
    onGlobalSetupPrebidDefaultsAccess(can)
    onGlobalSetupPrebidModuleAccess(can)
    onGlobalSetupPrebidModulesInventoryConnectionParamsOwnRead(can)
    onGlobalSetupPrebidProtectionAccess(can)
    onGlobalSetupSupplyChainAccess(can)
    onLayoutAccess(can)
    onPageAccess(can)
    onPerformanceAccess(can)
    onPortalAccess(can)
    onProductAccess(can)
    onTagAccess(can)
  }

  if (userBelongsToGroup('employeesOnly')) {
    onAdUnitCreateUpdateDelete(can)
    onAdUnitFieldsRead(can)
    onFinanceAccess(can)
    onFinanceAdminOptionReadUpdate(can)
    onGlobalSetupPriceGeniusRead(can)
    onGlobalSetupWorkspaceUpdate(can)
    onPerformanceAdminOptionReadUpdate(can)
    onProductStatusReadUpdate(can)
    onProductUpdate(can)
    onUserAccess(can)
    onWorkspaceAccess(can)
  }
}
