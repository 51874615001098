import { Box } from '@mui/material'
import { capitalCase } from 'change-case'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { Table, TableHeader } from 'components/Table'
import { useSort } from 'components/Table/Sort'
import {
  CONTAINER_SPACING,
  DISABLED_CONTAINER_OPACITY,
} from 'providers/material-ui/theme/constants'
import { ErrorBox } from '../../components/ErrorBox'
import { DEFAULT_SORT, KNOWLEDGE_BASE_URL, SORTABLE_COLUMNS } from './constants'
import { useGetWorkspaceKeyValues } from './hooks/useGetKeyValues'
import { WorkspaceKeyValueSlice, WorkspaceKeyValueSortBy } from './types'
import { useColumns } from './useColumns'

export const KeyValuesSection = ({ disabled }: { disabled: boolean }): JSX.Element => {
  const { t: tC } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.keyValues.list',
  })

  const columns = useColumns()
  const { props: sortProps, sort } = useSort<WorkspaceKeyValueSlice, WorkspaceKeyValueSortBy>(
    DEFAULT_SORT,
    SORTABLE_COLUMNS,
  )
  const { loading, workspaceKeyValues } = useGetWorkspaceKeyValues({ sort })

  const errors: string[] = workspaceKeyValues
    .filter((item: WorkspaceKeyValueSlice): boolean => item.error !== undefined)
    .map((item: WorkspaceKeyValueSlice): string => {
      const {
        error,
        keyValue: { name, type },
      } = item

      return t('error', { error, name, type: capitalCase(type) })
    })

  return (
    <Paper data-cy='key-values-section'>
      <Box
        {...(disabled && {
          sx: {
            opacity: DISABLED_CONTAINER_OPACITY,
            pointerEvents: 'none',
          },
        })}
      >
        <TableHeader
          description={t('description')}
          descriptionEndAdornment={
            <TextLink
              link={{
                title: tC('learnMore'),
                url: KNOWLEDGE_BASE_URL,
              }}
            />
          }
          title={t('title')}
        />

        <GapBox />

        <Table<WorkspaceKeyValueSlice>
          columns={columns}
          data={workspaceKeyValues}
          data-cy='workspace-key-values-table'
          loading={loading}
          sort={sortProps}
        />

        {errors && errors.length > 0 && (
          <ErrorBox
            errorMessage={errors}
            sx={{ mt: CONTAINER_SPACING }}
          />
        )}
      </Box>
    </Paper>
  )
}
