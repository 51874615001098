import { Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, RadioGroup, Switch, TagsField } from 'components/Form'
import { SelectCheckboxes } from 'components/Form/SelectCheckboxes'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { useAuthUser } from 'features/user'
import { useAbility } from 'providers/casl'
import { URL } from '../../constants'
import { Schema } from '../../schema'
import { useLineItemPriorities } from './hooks/useLineItemPriorities'
import { useMatchTypeRadioGroup } from './hooks/useMatchTypeRadioGroup'

export const TargetingSection = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const { control } = useFormContext<Schema>()
  const matchTypeOptions = useMatchTypeRadioGroup()
  const lineItemPrioritiesOptions = useLineItemPriorities()
  const link = { title: t('common:learnMore'), url: URL.KNOWLEDGE_BASE.LINE_ITEM_FIELDS }

  const {
    user: { currentWorkspace },
  } = useAuthUser()

  if (!currentWorkspace) {
    throw new Error('Workspace should be accessible')
  }

  const disabledFields = currentWorkspace.latestGamConnectionOrder?.status !== 'completed'

  const cannotUpdateDomain: boolean = ability.cannot('update', 'Domain')

  return (
    <Paper data-cy='targeting-section'>
      <SectionHeader
        subtitle={t('form.refresher.targeting.subtitle')}
        subtitleEndAdornment={
          <Link
            newTab
            to={URL.KNOWLEDGE_BASE.TARGETING}
          >
            {t('common:learnMore')}
          </Link>
        }
        title={t('form.refresher.targeting.title')}
        titleEndAdornment={
          <Switch
            control={control}
            disabled={cannotUpdateDomain}
            name='targetingEnabled'
          />
        }
      />

      <GapBox />

      <BoxForm>
        <RadioGroup
          control={control}
          disabled={cannotUpdateDomain}
          label={t('form.refresher.targeting.matchType.label')}
          name='matchType'
          options={matchTypeOptions}
          row
        />
        <Typography>{t('form.refresher.targeting.advertisers.title')}</Typography>
        <TagsField
          control={control}
          disabled={cannotUpdateDomain}
          label={t('form.refresher.targeting.advertisers.advertiserIds.label')}
          name='advertiserIds'
          tooltip={{ content: t('form.refresher.targeting.advertisers.advertiserIds.tooltip') }}
        />

        <Typography>{t('form.refresher.targeting.orders.title')}</Typography>
        <TagsField
          control={control}
          disabled={cannotUpdateDomain}
          label={t('form.refresher.targeting.orders.orderIds.label')}
          name='orderIds'
          tooltip={{ content: t('form.refresher.targeting.orders.orderIds.tooltip') }}
        />

        <Typography>{t('form.refresher.targeting.lineItems.title')}</Typography>
        <SelectCheckboxes
          control={control}
          disabled={disabledFields || cannotUpdateDomain}
          disabledFieldTooltip={{
            content: t('form.refresher.targeting.lineItems.lineItemPriority.disabledFieldTooltip'),
            link,
          }}
          label={t('form.refresher.targeting.lineItems.lineItemPriority.label')}
          name='lineItemPriority'
          options={lineItemPrioritiesOptions}
          tooltip={{
            content: t('form.refresher.targeting.lineItems.lineItemPriority.tooltipIcon'),
          }}
        />
        <TagsField
          control={control}
          disabled={disabledFields || cannotUpdateDomain}
          disabledFieldTooltip={{
            content: t('form.refresher.targeting.lineItems.lineItemName.disabledFieldTooltip'),
            link,
          }}
          label={t('form.refresher.targeting.lineItems.lineItemName.label')}
          name='lineItemName'
          tooltip={{ content: t('form.refresher.targeting.lineItems.lineItemName.tooltipIcon') }}
        />
        <TagsField
          control={control}
          disabled={cannotUpdateDomain}
          label={t('form.refresher.targeting.lineItems.lineItemIds.label')}
          name='lineItemIds'
          tooltip={{ content: t('form.refresher.targeting.lineItems.lineItemIds.tooltip') }}
        />
      </BoxForm>
    </Paper>
  )
}
