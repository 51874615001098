import { Can } from 'providers/casl'

export const onFinanceAccess = (can: Can) => {
  can('access', 'FinancePage')
}

export const onFinanceAdminOptionReadUpdate = (can: Can) => {
  can('read', 'Finance-adminOption')
  can('update', 'Finance-adminOption')
}
