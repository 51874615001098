import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { useCommonFormContextProps } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import Tooltip from 'components/Tooltip'
import {
  SetupPrebidStackSectionContext,
  SetupPrebidStackSectionContextType,
} from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { SetupPrebidStackForm } from 'features/globalSetup/forms/GoogleAdManager/SetupPrebidStackForm'
import { GamSetupOrder } from 'features/workspace/types/gamSetupOrder'
import { useAbility } from 'providers/casl'
import { ConnectionStatus } from './components/ConnectionStatus'
import { Dialog } from './components/Dialog'
import { KONWLEDGE_BASE_URL } from './constants'
import { useGetGamSetupOrder } from './hooks/useGetGamSetupOrder'
import { useGetSectionAvailability } from './hooks/useGetSectionAvailability'

export const SetupPrebidStackSection = (): JSX.Element => {
  const { t: tc } = useTranslation('common')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack',
  })
  const { available, styles, tooltip } = useGetSectionAvailability()
  const commonContextProps = useCommonFormContextProps({
    formId: 'setup-prebid-stack-form',
  })
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [gamSetupOrder, setGamSetupOrder] = useState<GamSetupOrder | undefined>(undefined)
  const { loading: loadingGamSetupOrder } = useGetGamSetupOrder(setGamSetupOrder)
  const ability = useAbility()
  const { loading, valid } = commonContextProps

  if (loadingGamSetupOrder) {
    return <Loading />
  }

  const contextProps: SetupPrebidStackSectionContextType = {
    available,
    gamSetupOrder,
    setGamSetupOrder,
    setIsDialogOpen,
    ...commonContextProps,
  }

  const isSaveButtonDisabled = !available || loading || !valid
  const isSaveButtonVisible =
    gamSetupOrder?.status !== 'inProgress' &&
    ability.can('update', 'GlobalSetupFeature-googleAdManagerConnection-setupPrebidStack')

  return (
    <SetupPrebidStackSectionContext.Provider value={contextProps}>
      <Paper data-cy='setup-prebid-stack-section'>
        <Tooltip
          content={tooltip}
          placement='top'
        >
          <Box sx={styles}>
            <SectionHeader
              subtitle={t('header.description')}
              subtitleEndAdornment={
                <TextLink
                  link={{
                    title: tc('learnMore'),
                    url: KONWLEDGE_BASE_URL,
                  }}
                />
              }
              title={t('header.title')}
            />

            <GapBox />

            <SetupPrebidStackForm />

            <ConnectionStatus />

            {isSaveButtonVisible && (
              <Button
                disabled={isSaveButtonDisabled}
                onClick={(): void => setIsDialogOpen(true)}
                variant='contained'
              >
                {t('form.start.label')}
              </Button>
            )}

            <Dialog
              isOpen={isDialogOpen}
              setOpen={setIsDialogOpen}
            />
          </Box>
        </Tooltip>
      </Paper>
    </SetupPrebidStackSectionContext.Provider>
  )
}
