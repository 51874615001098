import { Viewport } from 'features/globalSetup/features/viewport'
import { MediaType } from './mediaType'
import { VideoType } from './videoType'

export const mediaTypesKindValues = ['default', 'viewports'] as const

export type MediaTypesKind = (typeof mediaTypesKindValues)[number]

export type MediaTypes = {
  defaultAttribute: MediaTypeDefaultAttribute
  kind: MediaTypesKind
  nativeConfiguration: string
  videoConfiguration: string
  videoType: VideoType
  viewportAttributes: MediaTypeViewportAttribute[]
}

export type ViewportSlice = Pick<
  Viewport,
  | 'bannerEnabled'
  | 'bannerSizes'
  | 'id'
  | 'minimumWidth'
  | 'name'
  | 'nativeEnabled'
  | 'playerSize'
  | 'videoEnabled'
>

export type MediaTypeDefaultAttribute = {
  mediaTypeId: MediaType['id']
} & Pick<
  MediaType,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'playerSize' | 'updatedAt' | 'videoEnabled'
>

export type MediaTypeViewportAttribute = {
  viewport: ViewportSlice
} & MediaTypeDefaultAttribute
