import { User } from 'features/user'
import { Can } from '../Can'
import { onAdUnitMediaTypesCUD, onAdUnitUnfilledRecoveryUpdate } from './features/adUnit'
import {
  onDomainAdvancedSettingsUpdate,
  onDomainBulkManagementCreate,
  onDomainCreateUpdateDelete,
  onDomainCustomCodeUpdate,
  onDomainOverridenByYieldbirdRead,
} from './features/domain'
import {
  onGlobalSetupAmazonUpdate,
  onGlobalSetupAmazonYieldbirdAccountStatusUpdate,
} from './features/globalSetup/amazon'
import {
  onGlobalSetupPriceGeniusUpdate,
  onGlobalSetupWorkspaceUserACUD,
} from './features/globalSetup/common'
import { onGlobalSetupGoogleAdManagerConnectionUpdate } from './features/globalSetup/googleAccountManagerConnection'
import {
  onGlobalSetupBiddersBidParamsCommonUpdate,
  onGlobalSetupBiddersBidParamsOwnUpdate,
  onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate,
  onGlobalSetupBiddersBidParamsYieldbirdUpdate,
  onGlobalSetupBiddersCUD,
  onGlobalSetupBiddersOwnUpdate,
  onGlobalSetupBiddersThirdPartyUpdate,
  onGlobalSetupBiddersYieldbirdCpmConsentStatusUpdate,
  onGlobalSetupBiddersYieldbirdUpdate,
  onGlobalSetupPrebidBiddersAddaccess,
  onGlobalSetupPrebidDefaultsUpdate,
  onGlobalSetupPrebidModuleCUD,
  onGlobalSetupPrebidModulesGeneralParamsOwnUpdate,
  onGlobalSetupPrebidModulesGeneralParamsYieldbirdUpdate,
  onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate,
  onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead,
  onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate,
  onGlobalSetupPrebidModuleStatusUpdate,
  onGlobalSetupProtectionACUD,
} from './features/globalSetup/prebid'
import {
  aonGlobalSetupSupplyChainCUD,
  onGlobalSetupSupplyChainAddAccess,
} from './features/globalSetup/supplyChain'
import { onViewportCUD } from './features/globalSetup/viewport'
import { onPageAddAccess, onPageCreateUpdateDelete } from './features/page'
import { onPerformanceDataSamplingReadUpdate } from './features/performance'
import { onUserPageEmployeeACUD, onUserPagePublisherACUD } from './features/user'
import { onWorkspaceDelete, onWorkspaceSelectUse } from './features/workspace'

export const userByRole = (user: User, can: Can) => {
  switch (user.role) {
    case 'admin': {
      aonGlobalSetupSupplyChainCUD(can)
      onAdUnitMediaTypesCUD(can)
      onAdUnitUnfilledRecoveryUpdate(can)
      onDomainAdvancedSettingsUpdate(can)
      onDomainBulkManagementCreate(can)
      onDomainCreateUpdateDelete(can)
      onDomainCustomCodeUpdate(can)
      onDomainOverridenByYieldbirdRead(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatusUpdate(can)
      onGlobalSetupBiddersBidParamsCommonUpdate(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupBiddersBidParamsYieldbirdUpdate(can)
      onGlobalSetupBiddersCUD(can)
      onGlobalSetupBiddersOwnUpdate(can)
      onGlobalSetupBiddersThirdPartyUpdate(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatusUpdate(can)
      onGlobalSetupBiddersYieldbirdUpdate(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAddaccess(can)
      onGlobalSetupPrebidDefaultsUpdate(can)
      onGlobalSetupPrebidModuleCUD(can)
      onGlobalSetupPrebidModulesGeneralParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatusUpdate(can)
      onGlobalSetupPriceGeniusUpdate(can)
      onGlobalSetupProtectionACUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupWorkspaceUserACUD(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSamplingReadUpdate(can)
      onUserPageEmployeeACUD(can)
      onUserPagePublisherACUD(can)
      onViewportCUD(can)
      onWorkspaceDelete(can)
      break
    }

    case 'adops': {
      aonGlobalSetupSupplyChainCUD(can)
      onAdUnitMediaTypesCUD(can)
      onAdUnitUnfilledRecoveryUpdate(can)
      onDomainAdvancedSettingsUpdate(can)
      onDomainBulkManagementCreate(can)
      onDomainCreateUpdateDelete(can)
      onDomainCustomCodeUpdate(can)
      onDomainOverridenByYieldbirdRead(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatusUpdate(can)
      onGlobalSetupBiddersBidParamsCommonUpdate(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupBiddersBidParamsYieldbirdUpdate(can)
      onGlobalSetupBiddersCUD(can)
      onGlobalSetupBiddersOwnUpdate(can)
      onGlobalSetupBiddersThirdPartyUpdate(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatusUpdate(can)
      onGlobalSetupBiddersYieldbirdUpdate(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAddaccess(can)
      onGlobalSetupPrebidDefaultsUpdate(can)
      onGlobalSetupPrebidModuleCUD(can)
      onGlobalSetupPrebidModulesGeneralParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatusUpdate(can)
      onGlobalSetupPriceGeniusUpdate(can)
      onGlobalSetupProtectionACUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupWorkspaceUserACUD(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSamplingReadUpdate(can)
      onUserPagePublisherACUD(can)
      onViewportCUD(can)
      break
    }

    case 'backOffice': {
      onDomainCreateUpdateDelete(can)
      onDomainOverridenByYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onPageCreateUpdateDelete(can)
      onViewportCUD(can)
      break
    }

    case 'customerPerformance': {
      aonGlobalSetupSupplyChainCUD(can)
      onAdUnitMediaTypesCUD(can)
      onAdUnitUnfilledRecoveryUpdate(can)
      onDomainAdvancedSettingsUpdate(can)
      onDomainBulkManagementCreate(can)
      onDomainCreateUpdateDelete(can)
      onDomainOverridenByYieldbirdRead(can)
      onGlobalSetupAmazonUpdate(can)
      onGlobalSetupAmazonYieldbirdAccountStatusUpdate(can)
      onGlobalSetupBiddersBidParamsCommonUpdate(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupBiddersBidParamsYieldbirdUpdate(can)
      onGlobalSetupBiddersCUD(can)
      onGlobalSetupBiddersOwnUpdate(can)
      onGlobalSetupBiddersThirdPartyUpdate(can)
      onGlobalSetupBiddersYieldbirdCpmConsentStatusUpdate(can)
      onGlobalSetupBiddersYieldbirdUpdate(can)
      onGlobalSetupGoogleAdManagerConnectionUpdate(can)
      onGlobalSetupPrebidBiddersAddaccess(can)
      onGlobalSetupPrebidDefaultsUpdate(can)
      onGlobalSetupPrebidModuleCUD(can)
      onGlobalSetupPrebidModulesGeneralParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesGeneralParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsOwnUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdUpdate(can)
      onGlobalSetupPrebidModuleStatusUpdate(can)
      onGlobalSetupPriceGeniusUpdate(can)
      onGlobalSetupProtectionACUD(can)
      onGlobalSetupSupplyChainAddAccess(can)
      onGlobalSetupWorkspaceUserACUD(can)
      onPageAddAccess(can)
      onPageCreateUpdateDelete(can)
      onPerformanceDataSamplingReadUpdate(can)
      onUserPagePublisherACUD(can)
      onViewportCUD(can)
      break
    }

    case 'customerSuccess': {
      onDomainCreateUpdateDelete(can)
      onDomainOverridenByYieldbirdRead(can)
      onGlobalSetupBiddersBidParamsOwnUpdate(can)
      onGlobalSetupBiddersBidParamsThirdPartyProviderUpdate(can)
      onGlobalSetupPrebidModulesInventoryConnectionParamsYieldbirdRead(can)
      onGlobalSetupWorkspaceUserACUD(can)
      onPageCreateUpdateDelete(can)
      onUserPagePublisherACUD(can)
      onViewportCUD(can)
      break
    }

    case 'publisher': {
      onWorkspaceSelectUse(can)
      break
    }
  }
}
