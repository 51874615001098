import { Stack } from '@mui/material'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, NumberField, Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Loading from 'components/Loading'
import Paper from 'components/Paper'
import { useGetWorkspacePrebidConfiguration } from 'features/globalSetup'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { AddEditPageContext } from '../../contexts/AddEditPageContext'
import { KNOWLEDGE_BASE_URL } from './constants'
import { RelatedSettings, RelatedSettingsProps } from './RelatedSettings'
import { Schema } from './schema'

export const AuctionTimeoutSection = (): JSX.Element => {
  const { workspaceId } = useWorkspaceParam()
  const ability = useAbility()
  const { t } = useTranslation('features/page', { keyPrefix: 'form.prebidStack' })
  const { t: tc } = useTranslation('common')

  const { control, watch } = useFormContext<Schema>()
  const { customPrebidTimeoutEnabled } = watch()

  const { page } = useContext(AddEditPageContext)
  const { workspacePrebidConfiguration } = useGetWorkspacePrebidConfiguration({
    workspaceId,
  })

  if (!page || !workspacePrebidConfiguration) {
    return <Loading />
  }

  const {
    domain: { domainPrebidStack, id: domainId },
  } = page

  const relatedSettingsProps: RelatedSettingsProps = {
    domain: {
      domainId,
      domainPrebidStack: {
        customPrebidTimeoutEnabled: domainPrebidStack.customPrebidTimeoutEnabled,
        prebidTimeout: domainPrebidStack.prebidTimeout,
      },
    },
    workspace: {
      workspaceId,
      workspacePrebidConfiguration: { prebidTimeout: workspacePrebidConfiguration.prebidTimeout },
    },
  }

  const isDisabled: boolean = ability.cannot('update', 'Page')

  return (
    <Paper>
      <SectionHeader
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <Link
            newTab
            to={KNOWLEDGE_BASE_URL.AUCTION_TIMEOUT}
          >
            {tc('learnMore')}
          </Link>
        }
        title={t('title')}
      />

      <GapBox />

      <Stack
        alignItems='flex-start'
        direction='row'
        spacing={2}
      >
        <BoxForm>
          <Switch
            control={control}
            disabled={isDisabled}
            label={t('form.customPrebidTimeoutEnabled.label')}
            labelPlacement='start'
            name='customPrebidTimeoutEnabled'
            tooltip={{
              content: t('form.customPrebidTimeoutEnabled.tooltip'),
              link: {
                title: tc('learnMore'),
                url: KNOWLEDGE_BASE_URL.AUCTION_TIMEOUT,
              },
            }}
          />

          {customPrebidTimeoutEnabled && (
            <NumberField
              control={control}
              disabled={isDisabled}
              label={t('form.prebidTimeout.label')}
              name='prebidTimeout'
            />
          )}
        </BoxForm>

        {customPrebidTimeoutEnabled && <RelatedSettings {...relatedSettingsProps} />}
      </Stack>
    </Paper>
  )
}
