import { lazy, LazyExoticComponent } from 'react'

import { SvgElement } from 'types/image'
import { Product } from '..'

const PrebidStackLogo = lazy(() => import('images/logos/prebid-stack.svg?react'))
const PriceGeniusLogo = lazy(() => import('images/logos/price-genius.svg?react'))
const RefresherLogo = lazy(() => import('images/logos/refresher.svg?react'))
const UnfilledRecoveryLogo = lazy(() => import('images/logos/unfilled-recovery.svg?react'))
const ViewabilityToolsLogo = lazy(() => import('images/logos/viewability-tools.svg?react'))

type ProductsLogos = Record<Product, LazyExoticComponent<SvgElement>>

export const productsLogos: ProductsLogos = {
  prebidStack: PrebidStackLogo,
  priceGenius: PriceGeniusLogo,
  refresher: RefresherLogo,
  unfilledRecovery: UnfilledRecoveryLogo,
  viewabilityTools: ViewabilityToolsLogo,
} as const
