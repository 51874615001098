import { RouteObject } from 'react-router-dom'

import { PortalPage } from 'features/portal/pages/PortalPage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const portalRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='PortalPage'
        page={<PortalPage />}
      />
    ),
    path: ROUTES.PORTAL,
  },
]
