import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, RadioGroup, Select, TextField, TransferList } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TooltipProps } from 'components/Tooltip'
import {
  DomainSlice,
  useGetDomainsForSelect,
} from 'features/inventory/domain/hooks/useGetDomainsForSelect'
import { PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL } from 'features/inventory/page/constants'
import { useAbility } from 'providers/casl'
import { useGetAdUnitsForPageTransferList } from './hooks/useGetAdUnitsForPageTransferList'
import { useMatchTypeRadioGroup } from './hooks/useMatchTypeRadioGroup'
import { CommonSchema } from './schemas/commonSchema'
import { CommonFormProps } from './types'

export const CommonForm = ({ variant }: CommonFormProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/page', 'common'])

  const { control, watch } = useFormContext<CommonSchema>()
  const { domainId } = watch()

  const { domains } = useGetDomainsForSelect()
  const { active, available, loading } = useGetAdUnitsForPageTransferList({ domainId })
  const showTransferList = !loading && domainId.length > 0

  const matchTypeOptions = useMatchTypeRadioGroup()

  const domainIdTooltip: TooltipProps = {
    content: [
      t('form.general.basicAttributes.domainId.tooltip.line1'),
      t('form.general.basicAttributes.domainId.tooltip.line2'),
    ],
  }

  const pathTooltip: TooltipProps = {
    content: t('form.general.basicAttributes.path.tooltip'),
    link: {
      title: t('form.general.basicAttributes.matchType.tooltip.link'),
      url: PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL,
    },
  }

  const matchTypeTooltip: TooltipProps = {
    content: [
      t('form.general.basicAttributes.matchType.tooltip.line1'),
      t('form.general.basicAttributes.matchType.tooltip.line2'),
      t('form.general.basicAttributes.matchType.tooltip.line3'),
      t('form.general.basicAttributes.matchType.tooltip.line4'),
    ],
    link: {
      title: t('form.general.basicAttributes.matchType.tooltip.link'),
      url: PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL,
    },
  }

  const isDisabled: boolean =
    variant === 'add' ? ability.cannot('create', 'Page') : ability.cannot('update', 'Page')

  return (
    <>
      <Paper>
        <SectionHeader
          subtitle={t('form.general.basicAttributes.subtitle')}
          title={t('form.general.basicAttributes.title')}
        />

        <GapBox />

        <BoxForm>
          <Select<CommonSchema, DomainSlice>
            control={control}
            disabled={isDisabled || variant === 'edit'}
            label={t('form.general.basicAttributes.domainId.label')}
            name='domainId'
            options={domains}
            tooltip={domainIdTooltip}
          />

          <TextField
            control={control}
            disabled={isDisabled}
            label={t('form.general.basicAttributes.name.label')}
            name='name'
            placeholder={t('form.general.basicAttributes.name.placeholder')}
          />

          <TextField
            control={control}
            disabled={isDisabled}
            label={t('form.general.basicAttributes.path.label')}
            name='path'
            placeholder={t('form.general.basicAttributes.path.placeholder')}
            tooltip={pathTooltip}
          />

          <RadioGroup
            control={control}
            disabled={isDisabled}
            label={t('form.general.basicAttributes.matchType.name')}
            name='matchType'
            options={matchTypeOptions}
            tooltip={matchTypeTooltip}
          />
        </BoxForm>
      </Paper>

      {showTransferList && (
        <Paper>
          <SectionHeader
            subtitle={t('form.general.adUnitConnection.subtitle')}
            subtitleEndAdornment={
              <Link
                newTab
                to={PAGE_ARCHITECTURE_KNOWLEDGE_BASE_URL}
              >
                {t('common:learnMore')}
              </Link>
            }
            title={t('form.general.adUnitConnection.title')}
          />

          <GapBox />

          <TransferList
            control={control}
            disabled={isDisabled}
            leftList={available}
            name='adUnitIds'
            rightList={active}
          />
        </Paper>
      )}
    </>
  )
}
