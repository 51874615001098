import { Button, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FlexBox } from 'components/Box'
import { Switch } from 'components/Form'
import Tooltip from 'components/Tooltip'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../constants'
import { EditSchema } from '../schemas/editSchema'

export const SectionHeaderEndAdornment = (): JSX.Element => {
  const ability = useAbility()
  const { control } = useFormContext<EditSchema>()
  const { t } = useTranslation(['features/domain', 'common'])

  const cannotUpdateDomainCustomCode: boolean = ability.cannot('update', 'DomianFeature-customCode')

  return (
    <Stack
      direction='row'
      spacing={4}
    >
      <Switch
        control={control}
        disabled={cannotUpdateDomainCustomCode}
        name='customCodeEnabled'
        tooltip={{
          content: t('form.general.customCodeAttributes.enabled.tooltip'),
          link: {
            title: t('common:learnMore'),
            url: KNOWLEDGE_BASE_URL.CUSTOM_CODE,
          },
        }}
      />

      <FlexBox
        axis='x'
        gap={1}
      >
        <Button variant='contained'>
          {t('form.general.customCodeAttributes.templatesButton.title')}
        </Button>

        <Tooltip content={t('form.general.customCodeAttributes.templatesButton.tooltip')}></Tooltip>
      </FlexBox>
    </Stack>
  )
}
