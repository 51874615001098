import { CellContext, Row } from '@tanstack/react-table'
import { FieldArrayWithId } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EditSchema } from 'features/inventory/ad-unit/forms/GeneralForm/schemas/editSchema'

export const ViewportWidthCell = ({
  row: {
    index,
    original: {
      viewport: { minimumWidth: currentViewportWidth },
    },
  },
  table: { getRowModel },
}: CellContext<FieldArrayWithId<EditSchema>, unknown>): string => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.viewportList.cells.viewportWidth',
  })

  const rows: Row<FieldArrayWithId<EditSchema>>[] = getRowModel().rows

  const nextViewportWidth = (index: number): number =>
    rows[index + 1].original.viewport.minimumWidth

  for (let i = index; i < rows.length; i++) {
    if (i < rows.length - 1 && currentViewportWidth === nextViewportWidth(i)) {
      continue
    } else if (i < rows.length - 1) {
      return t('range', {
        currentViewportWidth,
        nextViewportWidth: nextViewportWidth(i) - 1,
      })
    } else {
      return t('last', { currentViewportWidth })
    }
  }

  throw new Error('Could not match Media Type range')
}
