import { Box, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { EmployeeBox, GapBox } from 'components/Box'
import { BoxForm, RadioGroup, Select } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Hint from 'components/Hint'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { SelectOption } from 'components/Select'
import { useGetYieldbirdStatusesForSelect } from 'features/globalSetup/hooks/useGetYieldbirdStatusesForSelect'
import { YieldbirdStatus } from 'features/globalSetup/types/yieldbirdStatus'
import { yieldbirdStatusColor } from 'features/globalSetup/utils/status'
import { useAbility } from 'providers/casl'
import { useAccountTypeOptions } from '../../../hooks/useAccountTypeOptions'
import {
  PrebidModuleSlice,
  useGetPrebidModulesForSelect,
} from '../../../hooks/useGetPrebidModulesForSelect'
import { EditSchema } from '../../../schemas/editSchema'
import { SELECT_HINT_LEARN_MORE } from '../constants'
import { CommonFormProps } from '../types'

export const BasicAttributesSection = ({ variant }: CommonFormProps): JSX.Element => {
  const theme = useTheme()
  const ability = useAbility()
  const { t: tc } = useTranslation('common')
  const { t: tcs } = useTranslation('features/globalSetup')
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.vendorSpecific.form.basicAttributes',
  })
  const accountTypeOptions = useAccountTypeOptions()
  const { control, watch } = useFormContext<EditSchema>()
  const { accountType, status } = watch()
  const statuses = useGetYieldbirdStatusesForSelect(status)

  const { options } = useGetPrebidModulesForSelect({ moduleType: 'vendorSpecific' })
  const filteredOptions = options.filter(
    (option: SelectOption<PrebidModuleSlice>): boolean => option.rawData?.account === accountType,
  )
  const disabled = variant === 'edit'

  const StatusInput: JSX.Element = (
    <Select
      control={control}
      disabled={ability.cannot('update', 'GlobalSetupField-prebid-modules-status')}
      label={tcs('common.status.label')}
      labelColor={({ value }: { value: YieldbirdStatus }) =>
        yieldbirdStatusColor({ status: value, theme })
      }
      name='status'
      options={statuses}
    />
  )

  return (
    <Paper>
      <SectionHeader
        subtitle={t('header.subtitle')}
        title={t('header.title')}
      />

      <GapBox />

      <BoxForm>
        <RadioGroup
          control={control}
          disabled={disabled}
          label={t('account.label')}
          name='accountType'
          options={accountTypeOptions}
          row
        />

        {accountType === 'yieldbird' &&
          variant === 'edit' &&
          (ability.can('update', 'GlobalSetupField-prebid-modules-status') ? (
            <EmployeeBox>{StatusInput}</EmployeeBox>
          ) : (
            StatusInput
          ))}

        <Box>
          <Select
            control={control}
            disabled={disabled}
            label={t('prebidModuleId.label')}
            name='prebidModuleId'
            options={filteredOptions}
          />

          <Hint
            content={t('prebidModuleId.hint')}
            contentEndAdornment={
              <Link
                newTab
                to={SELECT_HINT_LEARN_MORE}
              >
                {tc('learnMore')}
              </Link>
            }
          />
        </Box>
      </BoxForm>
    </Paper>
  )
}
