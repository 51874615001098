import { Box } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import _ from 'lodash'
import { useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { SectionHeader } from 'components/Header'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { Table } from 'components/Table'
import { URL } from '../../constants'
import { Schema } from '../../schema'
import { BasicSettings, TableRowData } from './types'
import { useColumns } from './useColumns'

export const BasicSettingsSection = (): JSX.Element => {
  const { t } = useTranslation('features/domain', { keyPrefix: 'form.refresher.basicSettings' })
  const { t: tc } = useTranslation('common')
  const { defaultValues } = useFormState<Schema>()

  const columns: ColumnDef<TableRowData>[] = useColumns()

  const formDefaults: BasicSettings = _.pick(defaultValues, [
    'desktopAdThreshold',
    'desktopMonitorUserScrollEnabled',
    'desktopRefreshInterval',
    'mobileAdThreshold',
    'mobileMonitorUserScrollEnabled',
    'mobileRefreshInterval',
  ]) as Required<BasicSettings>

  if (_.values(formDefaults).some(_.isNil)) {
    throw Error('Refresher Basic settings values should be defined')
  }

  const initialData: TableRowData[] = [
    {
      adThreshold: formDefaults.desktopAdThreshold,
      device: 'desktop',
      monitorUserScrollEnabled: formDefaults.desktopMonitorUserScrollEnabled,
      refreshInterval: formDefaults.desktopRefreshInterval,
    },
    {
      adThreshold: formDefaults.mobileAdThreshold,
      device: 'mobile',
      monitorUserScrollEnabled: formDefaults.mobileMonitorUserScrollEnabled,
      refreshInterval: formDefaults.mobileRefreshInterval,
    },
  ]

  return (
    <Paper data-cy='basic-settings-section'>
      <SectionHeader
        subtitle={t('subtitle')}
        subtitleEndAdornment={
          <Link
            newTab
            to={URL.KNOWLEDGE_BASE.BASIC_SETTINGS}
          >
            {tc('learnMore')}
          </Link>
        }
        title={t('title')}
      />

      <GapBox />

      <Box sx={{ width: 'fit-content' }}>
        <Table<TableRowData>
          columns={columns}
          data={initialData}
          meta={{ verticalBorders: true }}
        />
      </Box>
    </Paper>
  )
}
