import { Drawer, styled, Toolbar } from '@mui/material'

import { SIDEBAR_PADDING, SIDEBAR_SPACING } from 'features/layout/contants'
import { SidebarItem, SidebarProps } from './types'
import { useSidebar } from './useSidebar'

export const Sidebar = ({ collapsedX, config, onCollapsedX }: SidebarProps): JSX.Element => {
  const { renderItem } = useSidebar({ collapsedX, onCollapsedX })

  const DrawerStyled = styled(Drawer)(({ open, theme }) => ({
    '& .MuiDrawer-paper': {
      border: 0,
      padding: theme.spacing(SIDEBAR_PADDING),
      width: theme.spacing(open ? SIDEBAR_SPACING.MAX : SIDEBAR_SPACING.MIN),
    },
  }))

  return (
    <DrawerStyled
      open={!collapsedX}
      variant='permanent'
    >
      <Toolbar />
      {config.map((item: SidebarItem, index: number): JSX.Element => renderItem(item, index))}
    </DrawerStyled>
  )
}
