import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { SelectOption } from 'components/Select'
import { WorkspaceBidderContext } from 'features/globalSetup/contexts/WorkspaceBidderContext'
import { CommonForm } from 'features/globalSetup/forms/Prebid/Bidders/CommonForm'
import { DEFAULT_CONSENT_MODE, DEFAULT_CPM_ADJUSTMENT } from './constants'
import { AddSchema } from './schemas/addSchema'
import { useGetBiddersForSelect } from './sections/hooks/useGetBiddersForSelect'
import { BidderSlice } from './types'
import { isYieldbirdBidder } from './utils'

export const AddBidderFormBody = (): JSX.Element => {
  const { clearErrors, getValues, reset, setValue, watch } = useFormContext<AddSchema>()
  const { setAccountType } = useContext(WorkspaceBidderContext)

  const { accountType, bidderId } = watch()
  const bidders = useGetBiddersForSelect(accountType)

  const updateBidderDefaults = (): void => {
    const bidder: BidderSlice | undefined = bidders.find(
      (bidder: SelectOption<BidderSlice>) => bidder.value === bidderId,
    )?.rawData

    reset(
      {
        ...getValues(),
        bidCpmAdjustment: isYieldbirdBidder(accountType)
          ? DEFAULT_CPM_ADJUSTMENT.YIELDBIRD
          : DEFAULT_CPM_ADJUSTMENT.OWN,
        bidderCode: bidder?.code ?? '',
        bidderId: bidder?.id ?? '',
        bidderSid: isYieldbirdBidder(accountType) ? (bidder?.ybSid ?? '') : '',
        consentMode: DEFAULT_CONSENT_MODE,
      },
      { keepDirty: true },
    )
  }

  useEffect(() => {
    setValue('supplyChain', '')
    setValue('status', 'requested')
  }, [accountType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAccountType(accountType)
    clearErrors()
    updateBidderDefaults()
  }, [accountType, bidderId, bidders]) // eslint-disable-line react-hooks/exhaustive-deps

  return <CommonForm variant='add' />
}
