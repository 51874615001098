import { Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import DialogRoot, { DialogProps } from 'components/Dialog'
import { SaveButton } from 'components/Form'
import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'

export const Dialog = ({
  isOpen,
  setOpen,
}: Pick<DialogProps, 'isOpen' | 'setOpen'>): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack.form.dialog',
  })
  const { formId, gamSetupOrder, loading, valid } = useContext(SetupPrebidStackSectionContext)
  const mode = gamSetupOrder ? 'update' : 'create'

  const Content: JSX.Element = <Typography>{t('description')}</Typography>

  const Actions: JSX.Element = (
    <SaveButton
      {...{
        formId,
        loading,
        touched: true,
        valid,
      }}
      text={t('confirm')}
    />
  )

  return (
    <DialogRoot
      actions={Actions}
      content={Content}
      isOpen={isOpen}
      setOpen={setOpen}
      title={t(`title.${mode}`)}
    />
  )
}
