import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { HTMLAttributes } from 'react'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'

import { SelectOption } from 'components/Select'
import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { SelectCheckobxesProps } from './types'

export const SelectCheckboxes = <T extends FieldValues, Data>({
  control,
  disabled,
  disabledFieldTooltip,
  name,
  options,
  tooltip: tooltipProps,
  ...textFieldProps
}: SelectCheckobxesProps<T, Data>): JSX.Element => {
  const calculatedWidth: number | string = inputWidth[textFieldProps.width ?? 'md']
  const containerSxProps: SxProps<Theme> = { minWidth: calculatedWidth }

  const tryToGetLabel = (
    currentOption: SelectOption<Data>['value'],
  ): SelectOption<Data>['label'] | SelectOption<Data>['value'] =>
    options.find((o: SelectOption<Data>): boolean => o.value === currentOption)?.label ??
    currentOption

  const Input = (autocompleteProps: AutocompleteRenderInputParams): JSX.Element => {
    const inputSxProps: SxProps<Theme> = { ...containerSxProps, width: calculatedWidth }

    const Field = (
      <TextField
        {...autocompleteProps}
        {...textFieldProps}
        sx={inputSxProps}
      />
    )

    return disabledFieldTooltip && disabled ? (
      <Tooltip
        {...disabledFieldTooltip}
        placement='top'
      >
        {Field}
      </Tooltip>
    ) : (
      Field
    )
  }

  const Options = (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectOption<Data>['value'],
    { selected }: AutocompleteRenderOptionState,
  ): JSX.Element => (
    <li
      {...props}
      key={option}
    >
      <Checkbox
        checked={selected}
        checkedIcon={<CheckBoxIcon fontSize='small' />}
        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
      />
      {tryToGetLabel(option)}
    </li>
  )

  const Tags = (
    value: SelectOption<Data>['value'][],
    getTagProps: AutocompleteRenderGetTagProps,
  ): JSX.Element[] =>
    value.map(
      (option: string, index: number): JSX.Element => (
        <Chip
          {...getTagProps({ index })}
          key={option}
          label={tryToGetLabel(option)}
        />
      ),
    )

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl
          error={Boolean(error)}
          sx={containerSxProps}
        >
          <Stack
            direction='row'
            sx={containerSxProps}
          >
            <Autocomplete
              data-cy={`select-checkboxes-${name}`}
              disableClearable
              disableCloseOnSelect
              disabled={disabled}
              id={name}
              multiple
              onChange={(_, value): void => onChange(value)}
              options={options.map(o => o.value)}
              renderInput={Input}
              renderOption={Options}
              renderTags={Tags}
              value={value}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}
          </Stack>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
