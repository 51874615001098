import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField, Switch, TextField } from 'components/Form'
import { SetupPrebidStackSectionContext } from 'features/globalSetup/contexts/SetupPrebidStackSectionContext'
import { useAbility } from 'providers/casl'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { control, watch } = useFormContext<Schema>()
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'googleAdManagerConnection.setupPrebidStack',
  })
  const { instreamEnabled, nativeEnabled } = watch()
  const { available } = useContext(SetupPrebidStackSectionContext)
  const ability = useAbility()
  const disabled =
    !available ||
    ability.cannot('update', 'GlobalSetupFeature-googleAdManagerConnection-setupPrebidStack')

  return (
    <BoxForm sx={{ mb: 2 }}>
      <Switch
        control={control}
        disabled
        label={t('form.bannerEnabled.label')}
        labelPlacement='start'
        name='bannerEnabled'
      />

      <TextField
        control={control}
        disabled={disabled}
        label={t('form.bannerTemplateId.label')}
        name='bannerTemplateId'
      />

      <Switch
        control={control}
        disabled={disabled}
        label={t('form.instream.instreamEnabled.label')}
        labelPlacement='start'
        name='instreamEnabled'
      />

      {instreamEnabled && (
        <>
          <TextField
            control={control}
            disabled={disabled}
            label={t('form.instream.vastUrl.label')}
            name='vastUrl'
            tooltip={{
              content: t('form.instream.vastUrl.tooltip'),
            }}
          />

          <NumberField
            control={control}
            disabled={disabled}
            label={t('form.instream.duration.label')}
            name='duration'
            tooltip={{
              content: t('form.instream.duration.tooltip'),
            }}
          />

          <Switch
            control={control}
            disabled={disabled}
            label={t('form.instream.jsdelivrEnabled.label')}
            labelPlacement='start'
            name='jsdelivrEnabled'
            tooltip={{
              content: t('form.instream.jsdelivrEnabled.tooltip'),
            }}
          />
        </>
      )}

      <Switch
        control={control}
        disabled={disabled}
        label={t('form.nativeEnabled.label')}
        labelPlacement='start'
        name='nativeEnabled'
      />

      {nativeEnabled && (
        <TextField
          control={control}
          disabled={disabled}
          label={t('form.nativeTemplateId.label')}
          name='nativeTemplateId'
        />
      )}
    </BoxForm>
  )
}
