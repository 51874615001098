import { gql, TypedDocumentNode } from '@apollo/client'

import { ViewportSliceDTO } from './types'

export const VIEWPORT_FRAGMENT: TypedDocumentNode<ViewportSliceDTO> = gql`
  fragment ViewportFragment on Viewport {
    bannerEnabled
    bannerSizes {
      width
      height
      fluid
    }
    id
    minimumWidth
    name
    nativeEnabled
    videoEnabled
    playerSize {
      width
      height
      fluid
    }
    updatedAt
  }
`
