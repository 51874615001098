import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import { ModuleTable } from './components/ModuleTable'

export const ModulesSection = (): JSX.Element => {
  const { t } = useTranslation('features/domain')

  return (
    <Paper>
      <TableHeader
        description={t('form.prebidStack.modules.list.description')}
        title={t('form.prebidStack.modules.list.title')}
      />
      <GapBox />

      <ModuleTable moduleType='general' />
      <GapBox />

      <ModuleTable moduleType='userId' />
      <GapBox />

      <ModuleTable moduleType='vendorSpecific' />
      <GapBox />

      <ModuleTable moduleType='analytics' />
    </Paper>
  )
}
