import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { TooltipProps } from 'components/Tooltip'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useAbility } from 'providers/casl'
import { KNOWLEDGE_BASE_URL } from '../../constants'
import { usePriceGeniusAlwaysOn } from '../../hooks/usePriceGeniusAlwaysOn'
import { isSwitchDisabledBasedOnUnfilledRecovery } from '../../utils/unfilledRecovery'
import { CommonSchema } from './schemas/commonSchema'

export const CommonForm = (): JSX.Element => {
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { adUnit } = useContext(AddEditAdUnitContext)
  const { control } = useFormContext<CommonSchema>()
  const { priceGeniusAlwaysOn } = usePriceGeniusAlwaysOn()
  const ability = useAbility()
  const cannotUpdateAdUnit = ability.cannot('update', 'AdUnit')

  if (!adUnit) {
    throw Error('Ad unit should be accessible')
  }

  const { unfilledRecoveryAdUnit } = adUnit

  const isDisabled: boolean =
    isSwitchDisabledBasedOnUnfilledRecovery(unfilledRecoveryAdUnit, 'priceGenius') ||
    priceGeniusAlwaysOn

  const tooltip: TooltipProps = {
    content: t('form.priceGenius.enabled.tooltip'),
    link: {
      title: t('common:learnMore'),
      url: KNOWLEDGE_BASE_URL,
    },
  }

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.priceGenius.enabled.description')}
        title={t('form.priceGenius.enabled.title')}
        titleEndAdornment={
          <Switch
            control={control}
            disabled={cannotUpdateAdUnit || isDisabled}
            name='priceGeniusEnabled'
            {...(!unfilledRecoveryAdUnit && {
              tooltip,
              tooltipMode: 'wrap',
            })}
          />
        }
      />
    </Paper>
  )
}
