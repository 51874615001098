type SelectorProps =
  | {
      hint: number
    }
  | {
      step: number
      subTour?: number
      tour: number
    }

export const generateSelector = ({ ...props }: SelectorProps): string => {
  if ('tour' in props) {
    const { step, subTour, tour } = props

    return `tour-${tour}-${subTour ? `${subTour}-` : ''}step-${step}`
  } else {
    const { hint } = props

    return `hint-${hint}`
  }
}
