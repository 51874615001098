import { MoreVert } from '@mui/icons-material'
import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { COLUMN_SIZE } from 'components/Table/constants'
import { isSwitchDisabledBasedOnUnfilledRecovery } from 'features/inventory/ad-unit/utils/unfilledRecovery'
import { useAbility } from 'providers/casl'
import { AdUnitsAttributesSchema } from './schema'
import { AdUnitForProductTable, AdUnitsTableProduct } from './types'

export const useColumns = (product: AdUnitsTableProduct): ColumnDef<AdUnitForProductTable>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/adUnit')
  const { t: tProduct } = useTranslation('features/product')

  const { control } = useFormContext<AdUnitsAttributesSchema>()

  const cannotUpdatePrebidStack: boolean = ability.cannot('update', 'ProductToggle-prebidStack')

  return [
    {
      accessorKey: 'name',
      header: t('list.header.adUnitName'),
    },
    {
      accessorKey: 'divId',
      header: t('list.header.divId'),
    },
    {
      accessorKey: 'path',
      header: t('list.header.adUnitPath'),
    },
    {
      accessorKey: `${product}Enabled`,
      cell: ({
        cell: { getValue },
        row: {
          original: { id, unfilledRecoveryAdUnit },
        },
      }) => (
        <Switch<AdUnitsAttributesSchema>
          control={control}
          disabled={
            cannotUpdatePrebidStack ||
            isSwitchDisabledBasedOnUnfilledRecovery(unfilledRecoveryAdUnit, product)
          }
          name={`adUnitsAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: tProduct(`product.${product}`),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('list.header.lastModified'),
    },
    {
      cell: () => <MoreVert />,
      header: t('list.header.actions'),
      id: 'actions',
      meta: {
        centered: true,
        nonClickable: true,
      },
      size: COLUMN_SIZE.SM,
    },
  ]
}
