import { gql, TypedDocumentNode } from '@apollo/client'

import { GamSetupOrderDTO } from '../types/gamSetupOrderDTO'

export const GAM_SETUP_ORDER_FRAGMENT: TypedDocumentNode<GamSetupOrderDTO> = gql`
  fragment GamSetupOrderFragment on GamSetupOrder {
    bannerTemplateId
    createdAt
    duration
    errorMessage
    id
    instreamEnabled
    jsdelivrEnabled
    nativeEnabled
    nativeTemplateId
    status
    updatedAt
    vastUrl
  }
`
