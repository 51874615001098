import { SvgIcon } from '@mui/material'
import { lazy, Suspense } from 'react'

import Loading from 'components/Loading'

const Icon = lazy(() => import('images/logos/yieldbird.svg?react'))

export const IconYieldbird = (): JSX.Element => (
  <Suspense fallback={<Loading />}>
    <SvgIcon
      component={Icon}
      inheritViewBox
      sx={{
        height: 'inherit',
        width: 'inherit',
      }}
    />
  </Suspense>
)
