import { AdUnitSizeDTO } from 'features/globalSetup/features/viewport'
import { adUnitSizeFromDTO } from 'features/globalSetup/features/viewport/utils'
import { MediaTypeDTO } from 'features/inventory/ad-unit/api/types/mediaTypeDTO'
import { MediaType } from 'features/inventory/ad-unit/types/mediaType'

type CommonMediaTypeFieldsDTO = Pick<
  MediaTypeDTO,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'playerSize' | 'videoEnabled'
>

type CommonMediaTypeFields = Pick<
  MediaType,
  'bannerEnabled' | 'bannerSizes' | 'nativeEnabled' | 'playerSize' | 'videoEnabled'
>

export const commonMediaTypeFieldsFromDTO = (
  from: CommonMediaTypeFieldsDTO,
): CommonMediaTypeFields => {
  const { bannerSizes, playerSize } = from

  return {
    ...from,
    bannerSizes: bannerSizes.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
    playerSize: playerSize.map((size: AdUnitSizeDTO): string => adUnitSizeFromDTO(size)),
  }
}
