import { mapDTO } from '@twistezo/ts-dto-mapper'

import { DomainRefresher } from '../../types/DomainRefresher'
import { DomainRefresherDTO } from '../types/domainRefresherDTO'

export const domainRefresherFromDTO = (from: DomainRefresherDTO) =>
  mapDTO<DomainRefresherDTO, DomainRefresher>({ from }).transform(domainRefresherDTO => {
    const {
      createdAt,
      desktopAdThreshold,
      desktopRefreshInterval,
      mobileAdThreshold,
      mobileRefreshInterval,
      updatedAt,
    } = domainRefresherDTO

    return {
      ...domainRefresherDTO,
      createdAt: new Date(createdAt),
      desktopAdThreshold: Math.round(desktopAdThreshold * 100),
      desktopRefreshInterval: desktopRefreshInterval / 1000,
      mobileAdThreshold: Math.round(mobileAdThreshold * 100),
      mobileRefreshInterval: mobileRefreshInterval / 1000,
      updatedAt: new Date(updatedAt),
    }
  })
