import { gql, TypedDocumentNode } from '@apollo/client'

import { DomainRefresherDTO } from '../types/domainRefresherDTO'

export const DOMAIN_REFRESHER_FRAGMENT: TypedDocumentNode<DomainRefresherDTO> = gql`
  fragment DomainRefresherFragment on DomainRefresher {
    advertiserIds
    createdAt
    desktopAdThreshold
    desktopMonitorUserScrollEnabled
    desktopRefreshInterval
    domainId
    id
    lineItemIds
    lineItemName
    lineItemPriority
    matchType
    mobileAdThreshold
    mobileMonitorUserScrollEnabled
    mobileRefreshInterval
    orderIds
    refresherEnabled
    targetingEnabled
    updatedAt
  }
`
