import { Divider } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { GapBox } from 'components/Box'
import { BoxForm, CodeField, RadioGroup, Select } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { useAbility } from 'providers/casl'
import { AdUnitForForm } from '../../hooks/useGetAdUnit'
import { EditSchema, editSchemaDefault } from '../../schemas/editSchema'
import { MediaTypeSchema, MediaTypeViewportsSchema } from '../../schemas/mediaTypesSchema'
import { DefaultTable } from './components/DefaultTable'
import { ViewportsTable } from './components/ViewportsTable'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useMediaTypesKindOptions } from './hooks/useMediaTypesKindOptions'
import { useVideoTypeOptions } from './hooks/useVideoTypeOptions'

export const MediaTypesSection = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { adUnit } = useContext(AddEditAdUnitContext)
  const { control, getValues, reset, watch } = useFormContext<EditSchema>()

  if (!adUnit) {
    throw new Error('Ad unit should be accessible')
  }

  const mediaTypeOptions = useMediaTypesKindOptions()
  const videoTypeOptions = useVideoTypeOptions()
  const {
    mediaTypes: { kind },
  } = watch()

  const resetMediaTypesAttributes = (adUnit: AdUnitForForm): void => {
    const { mediaTypes, ...values } = getValues()

    const defaultAttribute: MediaTypeSchema = editSchemaDefault(adUnit, ability).mediaTypes
      .defaultAttribute
    const viewportAttributes: MediaTypeViewportsSchema[] = editSchemaDefault(adUnit, ability)
      .mediaTypes.viewportAttributes

    reset({
      ...values,
      mediaTypes: {
        ...mediaTypes,
        ...(kind === 'default' ? { viewportAttributes } : { defaultAttribute }),
      },
    })
  }

  useEffect(() => {
    resetMediaTypesAttributes(adUnit)
  }, [adUnit, kind]) // eslint-disable-line react-hooks/exhaustive-deps

  const cannotUpdateAdUnit = ability.cannot('update', 'AdUnit')
  const disabledCodeFields = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return (
    <Paper>
      <SectionHeader
        subtitle={t('form.general.mediaTypes.subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL.MEDIA_TYPES,
            }}
          />
        }
        title={t('form.general.mediaTypes.title')}
      />

      <GapBox />

      <RadioGroup
        control={control}
        disabled={cannotUpdateAdUnit}
        label={t('form.general.mediaTypes.kind.label')}
        name='mediaTypes.kind'
        options={mediaTypeOptions}
        row
      />

      <GapBox />

      {kind === 'default' ? <DefaultTable /> : <ViewportsTable />}

      <Divider sx={{ my: 3 }} />

      <SectionHeader
        subtitle={t('form.general.mediaTypes.configuration.subtitle')}
        title={t('form.general.mediaTypes.configuration.title')}
      />

      <GapBox />

      <BoxForm>
        <Select
          control={control}
          disabled={cannotUpdateAdUnit}
          label={t('form.general.mediaTypes.configuration.videoType.label')}
          name='mediaTypes.videoType'
          options={videoTypeOptions}
          tooltip={{ content: t('form.general.mediaTypes.configuration.videoType.tooltip') }}
        />

        <CodeField
          control={control}
          disabled={cannotUpdateAdUnit || disabledCodeFields}
          language='json'
          name='mediaTypes.videoConfiguration'
          title={t('form.general.mediaTypes.configuration.videoConfiguration.header')}
          tooltip={{
            content: t('form.general.mediaTypes.configuration.videoConfiguration.tooltip.content'),
            link: {
              title: t('form.general.mediaTypes.configuration.videoConfiguration.tooltip.title'),
              url: KNOWLEDGE_BASE_URL.VIDEO_CONFIGURATION,
            },
          }}
        />

        <CodeField
          control={control}
          disabled={cannotUpdateAdUnit || disabledCodeFields}
          language='json'
          name='mediaTypes.nativeConfiguration'
          title={t('form.general.mediaTypes.configuration.nativeConfiguration.header')}
          tooltip={{
            content: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.content'),
            link: {
              title: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.title'),
              url: KNOWLEDGE_BASE_URL.NATIVE_CONFIGURATION,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}
