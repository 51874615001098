import { useTranslation } from 'react-i18next'

import { Tab } from 'components/Tabs'
import { GeneralFormEdit } from 'features/inventory/ad-unit/forms/GeneralForm'
import PrebidStackForm from 'features/inventory/ad-unit/forms/PrebidStackForm'
import { PriceGeniusCommonForm } from 'features/inventory/ad-unit/forms/PriceGeniusForm'
import RefresherForm from 'features/inventory/ad-unit/forms/RefresherForm'
import UnfilledRecoveryForm from 'features/inventory/ad-unit/forms/UnfilledRecoveryForm'
import ViewabilityToolsForm from 'features/inventory/ad-unit/forms/ViewabilityToolsForm'
import { useAbility } from 'providers/casl'
import { generateSelector } from 'providers/product-fruits/utils'
import { ProductTab } from 'types/productTab'

export const useProductTabs = (): Tab<ProductTab>[] => {
  const ability = useAbility()
  const { t } = useTranslation('features/adUnit')
  const { t: tProduct } = useTranslation('features/product')

  const canAccess = ability.can('access', 'AdUnitPage-edit')

  return [
    {
      disabled: !canAccess,
      element: 'general',
      label: t('edit.tabs.general'),
      page: <GeneralFormEdit />,
      productFruitsSelector: generateSelector({ step: 1, tour: 2 }),
    },
    {
      authorized: 'ProductForm-prebidStack',
      disabled: !canAccess || !ability.can('access', 'ProductForm-prebidStack'),
      element: 'prebid-stack',
      label: tProduct('product.prebidStack'),
      page: <PrebidStackForm />,
    },
    {
      authorized: 'ProductForm-refresher',
      disabled: !canAccess || !ability.can('access', 'ProductForm-refresher'),
      element: 'refresher',
      label: tProduct('product.refresher'),
      page: <RefresherForm />,
    },
    {
      authorized: 'ProductForm-viewabilityTools',
      disabled: !canAccess || !ability.can('access', 'ProductForm-viewabilityTools'),
      element: 'viewability-tools',
      label: tProduct('product.viewabilityTools'),
      page: <ViewabilityToolsForm />,
    },
    {
      authorized: 'ProductForm-priceGenius',
      disabled: !canAccess || !ability.can('access', 'ProductForm-priceGenius'),
      element: 'price-genius',
      label: tProduct('product.priceGenius'),
      page: <PriceGeniusCommonForm />,
    },
    {
      authorized: 'ProductForm-unfilledRecovery',
      disabled: !canAccess || !ability.can('access', 'ProductForm-unfilledRecovery'),
      element: 'unfilled-recovery',
      label: tProduct('product.unfilledRecovery'),
      page: <UnfilledRecoveryForm />,
    },
  ]
}
