import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { GapBox } from 'components/Box'
import Filter, { FilterValue } from 'components/Filter'
import { Link } from 'components/Link'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import PagesTable from 'features/inventory/page/components/PagesTable'
import { useWorkspaceParam } from 'features/workspace/hooks/useWorkspaceParam'
import { useAbility } from 'providers/casl'
import { ROUTES } from 'routes'

export const PageList = (): JSX.Element => {
  const ability = useAbility()
  const { workspaceId } = useWorkspaceParam()
  const { t } = useTranslation('features/page')
  const [filters, setFilters] = useState<FilterValue[]>([])

  const PageFilter = (
    <Filter
      id='pageFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  const AddNew = (): JSX.Element => (
    <Link to={generatePath(ROUTES.INVENTORY.PAGE.ADD, { workspaceId })}>
      <Button
        data-cy='page-button-add'
        variant='contained'
      >
        {t('list.add')}
      </Button>
    </Link>
  )

  const canAccessAdd: boolean = ability.can('access', 'PagePage-add')

  return (
    <Paper>
      <TableHeader
        actions={canAccessAdd ? <AddNew /> : undefined}
        description={canAccessAdd ? t('list.description.employee') : t('list.description.viewer')}
        filter={PageFilter}
        title={t('list.title')}
      />

      <GapBox />

      <PagesTable filters={filters} />
    </Paper>
  )
}
