import { lazy } from 'react'

import { Logos } from 'types/logos'

const Across33 = lazy(() => import('images/logos/workspaceBidder/33Across.svg?react'))
const OneFiftyTwoMedia = lazy(() => import('images/logos/workspaceBidder/152 media.svg?react'))
const Adagio = lazy(() => import('images/logos/workspaceBidder/Adagio.svg?react'))
const Adbite = lazy(() => import('images/logos/workspaceBidder/Adbite.svg?react'))
const AdForm = lazy(() => import('images/logos/workspaceBidder/AdForm.svg?react'))
const AdFusion = lazy(() => import('images/logos/workspaceBidder/AdFusion.svg?react'))
const AdKernel = lazy(() => import('images/logos/workspaceBidder/AdKernel.svg?react'))
const AdMixer = lazy(() => import('images/logos/workspaceBidder/AdMixer.svg?react'))
const Adnuntius = lazy(() => import('images/logos/workspaceBidder/Adnuntius.svg?react'))
const AdPone = lazy(() => import('images/logos/workspaceBidder/AdPone.svg?react'))
const Adquery = lazy(() => import('images/logos/workspaceBidder/Adquery.svg?react'))
const Adrino = lazy(() => import('images/logos/workspaceBidder/Adrino.svg?react'))
const Adtelligent = lazy(() => import('images/logos/workspaceBidder/Adtelligent.svg?react'))
const AdYouLike = lazy(() => import('images/logos/workspaceBidder/AdYouLike.svg?react'))
const Amx = lazy(() => import('images/logos/workspaceBidder/AMX RTB.svg?react'))
const AOL = lazy(() => import('images/logos/workspaceBidder/AOL.svg?react'))
const AppNexus = lazy(() => import('images/logos/workspaceBidder/AppNexus.svg?react'))
const Beop = lazy(() => import('images/logos/workspaceBidder/Beop.svg?react'))
const Bizzclick = lazy(() => import('images/logos/workspaceBidder/Bizz click.svg?react'))
const Blasto = lazy(() => import('images/logos/workspaceBidder/Blasto.svg?react'))
const Brightcom = lazy(() => import('images/logos/workspaceBidder/Brightcom.svg?react'))
const Businessclick = lazy(() => import('images/logos/workspaceBidder/Businessclick.svg?react'))
const CadentAperture = lazy(() => import('images/logos/workspaceBidder/Cadent Aperture.svg?react'))
const Caroda = lazy(() => import('images/logos/workspaceBidder/Caroda.svg?react'))
const Clickonometrics = lazy(() => import('images/logos/workspaceBidder/Clickonometrics.svg?react'))
const Colossusssp = lazy(() => import('images/logos/workspaceBidder/Colossusssp.svg?react'))
const ConnectAd = lazy(() => import('images/logos/workspaceBidder/ConnectAd.svg?react'))
const Conversant = lazy(() => import('images/logos/workspaceBidder/Conversant.svg?react'))
const Criteo = lazy(() => import('images/logos/workspaceBidder/Criteo.svg?react'))
const DistrictM = lazy(() => import('images/logos/workspaceBidder/DistrictM.svg?react'))
const DspX = lazy(() => import('images/logos/workspaceBidder/DSPx.svg?react'))
const EmxDigital = lazy(() => import('images/logos/workspaceBidder/EMX Digital.svg?react'))
const Equativ = lazy(() => import('images/logos/workspaceBidder/Equativ.svg?react'))
const Eskimi = lazy(() => import('images/logos/workspaceBidder/Eskimi.svg?react'))
const Etarget = lazy(() => import('images/logos/workspaceBidder/Etarget.svg?react'))
const FreeWheel = lazy(() => import('images/logos/workspaceBidder/Freewheel.svg?react'))
const Grid = lazy(() => import('images/logos/workspaceBidder/Grid.svg?react'))
const GumGum = lazy(() => import('images/logos/workspaceBidder/GumGum.svg?react'))
const Imds = lazy(() => import('images/logos/workspaceBidder/iMedia Digital Services.svg?react'))
const Improvedigital = lazy(() => import('images/logos/workspaceBidder/Improve Digital.svg?react'))
const IncrementX = lazy(() => import('images/logos/workspaceBidder/IncrementX.svg?react'))
const IX = lazy(() => import('images/logos/workspaceBidder/Index Exchange.svg?react'))
const Insticator = lazy(() => import('images/logos/workspaceBidder/Insticator.svg?react'))
const Invibes = lazy(() => import('images/logos/workspaceBidder/Invibes.svg?react'))
const JustPremium = lazy(() => import('images/logos/workspaceBidder/JustPremium.svg?react'))
const Livewrapped = lazy(() => import('images/logos/workspaceBidder/Livewrapped.svg?react'))
const Luponmedia = lazy(() => import('images/logos/workspaceBidder/Lupon Media.svg?react'))
const Magnite = lazy(() => import('images/logos/workspaceBidder/Magnite.svg?react'))
const MediaFuse = lazy(() => import('images/logos/workspaceBidder/MediaFuse.svg?react'))
const Mgid = lazy(() => import('images/logos/workspaceBidder/Mgid.svg?react'))
const MinuteMedia = lazy(() => import('images/logos/workspaceBidder/MinuteMedia.svg?react'))
const Monetix = lazy(() => import('images/logos/workspaceBidder/Monetix.svg?react'))
const Mytarget = lazy(() => import('images/logos/workspaceBidder/Mytarget.svg?react'))
const Nativo = lazy(() => import('images/logos/workspaceBidder/Nativo.svg?react'))
const NextMillennium = lazy(() => import('images/logos/workspaceBidder/NextMillennium.svg?react'))
const Nexx360 = lazy(() => import('images/logos/workspaceBidder/Nexx360.svg?react'))
const Nobid = lazy(() => import('images/logos/workspaceBidder/Nobid.svg?react'))
const Ogury = lazy(() => import('images/logos/workspaceBidder/Ogury.svg?react'))
const OMS = lazy(() => import('images/logos/workspaceBidder/OMS.svg?react'))
const Onetag = lazy(() => import('images/logos/workspaceBidder/Onetag.svg?react'))
const OpenX = lazy(() => import('images/logos/workspaceBidder/OpenX.svg?react'))
const Optidigital = lazy(() => import('images/logos/workspaceBidder/Optidigital.svg?react'))
const Oraki = lazy(() => import('images/logos/workspaceBidder/Oraki.svg?react'))
const OTM = lazy(() => import('images/logos/workspaceBidder/OTM.svg?react'))
const Outbrain = lazy(() => import('images/logos/workspaceBidder/Outbrain.svg?react'))
const Pangle = lazy(() => import('images/logos/workspaceBidder/Pangle.svg?react'))
const Pubmatic = lazy(() => import('images/logos/workspaceBidder/Pubmatic.svg?react'))
const PulsePoint = lazy(() => import('images/logos/workspaceBidder/PulsePoint.svg?react'))
const Quantcast = lazy(() => import('images/logos/workspaceBidder/Quantcast.svg?react'))
const R2B2 = lazy(() => import('images/logos/workspaceBidder/R2B2.svg?react'))
const RhythmOne = lazy(() => import('images/logos/workspaceBidder/RhythmOne.svg?react'))
const RichAudience = lazy(() => import('images/logos/workspaceBidder/Richaudience.svg?react'))
const Rise = lazy(() => import('images/logos/workspaceBidder/Rise.svg?react'))
const Rtbhouse = lazy(() => import('images/logos/workspaceBidder/RTB House.svg?react'))
const Rubicon = lazy(() => import('images/logos/workspaceBidder/Rubicon.svg?react'))
const Seedtag = lazy(() => import('images/logos/workspaceBidder/Seedtag.svg?react'))
const Setupad = lazy(() => import('images/logos/workspaceBidder/Setupad.svg?react'))
const Sharethrough = lazy(() => import('images/logos/workspaceBidder/Sharethrough.svg?react'))
const Smaato = lazy(() => import('images/logos/workspaceBidder/Smaato.svg?react'))
const Smart = lazy(() => import('images/logos/workspaceBidder/Smart.svg?react'))
const SmartX = lazy(() => import('images/logos/workspaceBidder/Smartx.svg?react'))
const SmartyAds = lazy(() => import('images/logos/workspaceBidder/SmartyAds.svg?react'))
const SmileWanted = lazy(() => import('images/logos/workspaceBidder/SmileWanted.svg?react'))
const Sonobi = lazy(() => import('images/logos/workspaceBidder/Sonobi.svg?react'))
const Sovrn = lazy(() => import('images/logos/workspaceBidder/Sovrn.svg?react'))
const Spotx = lazy(() => import('images/logos/workspaceBidder/Spotx.svg?react'))
const StroeerCore = lazy(() => import('images/logos/workspaceBidder/Stroer.svg?react'))
const Taboola = lazy(() => import('images/logos/workspaceBidder/Taboola.svg?react'))
const Tappx = lazy(() => import('images/logos/workspaceBidder/Tappx.svg?react'))
const Teads = lazy(() => import('images/logos/workspaceBidder/Teads.svg?react'))
const TripleLift = lazy(() => import('images/logos/workspaceBidder/TripleLift.svg?react'))
const Underdogmedia = lazy(() => import('images/logos/workspaceBidder/Underdog Media.svg?react'))
const Unruly = lazy(() => import('images/logos/workspaceBidder/Unruly.svg?react'))
const Vdo = lazy(() => import('images/logos/workspaceBidder/Vdo.svg?react'))
const Verizonmedia = lazy(() => import('images/logos/workspaceBidder/Verizon media.svg?react'))
const Vidoomy = lazy(() => import('images/logos/workspaceBidder/Vidoomy.svg?react'))
const Widespace = lazy(() => import('images/logos/workspaceBidder/Widespace.svg?react'))
const Xandr = lazy(() => import('images/logos/workspaceBidder/Xandr.svg?react'))
const Xaxis = lazy(() => import('images/logos/workspaceBidder/Xaxis.svg?react'))
const Yahoossp = lazy(() => import('images/logos/workspaceBidder/Yahoo SSP.svg?react'))
const Yieldlab = lazy(() => import('images/logos/workspaceBidder/Yieldlab.svg?react'))
const Yieldmo = lazy(() => import('images/logos/workspaceBidder/Yieldmo.svg?react'))
const Yoc = lazy(() => import('images/logos/workspaceBidder/Yoc.svg?react'))
const Zmaticoo = lazy(() => import('images/logos/workspaceBidder/Zmaticoo.svg?react'))

export const bidderLogos: Logos = {
  '33Across': Across33,
  '152 media': OneFiftyTwoMedia,
  Adagio,
  Adbite,
  AdForm,
  AdFusion,
  AdKernel,
  AdMixer,
  Adnuntius,
  AdPone,
  Adquery,
  Adrino,
  Adtelligent,
  AdYouLike,
  'AMX RTB': Amx,
  AOL,
  AppNexus,
  AppNexus152: AppNexus,
  AppNexusAst: AppNexus,
  Beop,
  'Bizz click': Bizzclick,
  Blasto,
  Brightcom,
  'Brightcom SSP': Brightcom,
  Businessclick,
  'Cadent Aperture MX': CadentAperture,
  Caroda,
  Clickonometrics,
  Colossusssp,
  ConnectAd,
  Conversant,
  Criteo,
  DistrictM,
  DSPx: DspX,
  'EMX Digital': EmxDigital,
  Equativ,
  Eskimi,
  Etarget,
  FreeWheel,
  Grid,
  GumGum,
  'iMedia Digital Services': Imds,
  'Improve Digital': Improvedigital,
  IncrementX,
  'Index Exchange': IX,
  Insticator,
  Invibes,
  JustPremium,
  Livewrapped,
  'Lupon Media': Luponmedia,
  Magnite,
  MediaFuse,
  Mgid,
  MinuteMedia,
  Monetix,
  Mytarget,
  Nativo,
  NextMillennium,
  Nexx360,
  Nobid,
  Ogury,
  Onetag,
  'Online Media Solutions': OMS,
  OpenX,
  Optidigital,
  Oraki,
  OTM,
  Outbrain,
  Pangle,
  Pubmatic,
  PulsePoint,
  Quantcast,
  R2B2,
  RhythmOne,
  RichAudience,
  Rise,
  'RTB House': Rtbhouse,
  Rubicon,
  Rubicon152: Rubicon,
  Seedtag,
  Setupad,
  Sharethrough,
  Smaato,
  Smart,
  SmartX,
  SmartyAds,
  'Smile Wanted': SmileWanted,
  Sonobi,
  Sovrn,
  Spotx,
  'Stroeer Core': StroeerCore,
  Taboola,
  Tappx,
  Teads,
  TripleLift,
  'Underdog Media': Underdogmedia,
  Unruly,
  'VDO.AI': Vdo,
  'Verizon media': Verizonmedia,
  Vidoomy,
  Widespace,
  Xandr,
  Xaxis,
  'Yahoo SSP': Yahoossp,
  Yieldlab,
  Yieldmo,
  Yoc,
  zMaticoo: Zmaticoo,
}
