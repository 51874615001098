import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch, TagsField } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { PREDEFINED_AD_UNIT_SIZES } from 'features/globalSetup/features/viewport'
import { MediaTypeDefaultAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { useAbility } from 'providers/casl'
import { CommonSchema } from '../../../../schemas/commonSchema'

export const useColumns = (): ColumnDef<MediaTypeDefaultAttribute>[] => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.defaultList.header',
  })
  const { control } = useFormContext<CommonSchema>()
  const ability = useAbility()
  const cannotUpdateAdUnit = ability.cannot('update', 'AdUnit')
  const cannotUpdateMediaTypes = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return [
    {
      accessorKey: 'bannerEnabled',
      cell: ({ getValue }) => (
        <Switch
          control={control}
          disabled={cannotUpdateAdUnit || cannotUpdateMediaTypes}
          name='mediaTypes.defaultAttribute.bannerEnabled'
          value={Boolean(getValue())}
        />
      ),
      header: t('bannerEnabled'),
    },
    {
      accessorKey: 'bannerSizes',
      cell: () => (
        <TagsField
          control={control}
          disabled={cannotUpdateAdUnit || cannotUpdateMediaTypes}
          name={'mediaTypes.defaultAttribute.bannerSizes'}
          options={PREDEFINED_AD_UNIT_SIZES}
        />
      ),
      header: t('bannerSizes'),
    },
    {
      accessorKey: 'videoEnabled',
      cell: ({ getValue }) => (
        <Switch
          control={control}
          disabled={cannotUpdateAdUnit || cannotUpdateMediaTypes}
          name='mediaTypes.defaultAttribute.videoEnabled'
          value={Boolean(getValue())}
        />
      ),
      header: t('videoEnabled'),
    },
    {
      accessorKey: 'playerSize',
      cell: () => (
        <TagsField
          control={control}
          disabled={cannotUpdateAdUnit || cannotUpdateMediaTypes}
          name={'mediaTypes.defaultAttribute.playerSize'}
          options={PREDEFINED_AD_UNIT_SIZES}
        />
      ),
      header: t('playerSize'),
    },
    {
      accessorKey: 'nativeEnabled',
      cell: ({ getValue }) => (
        <Switch
          control={control}
          disabled={cannotUpdateAdUnit || cannotUpdateMediaTypes}
          name='mediaTypes.defaultAttribute.nativeEnabled'
          value={Boolean(getValue())}
        />
      ),
      header: t('nativeEnabled'),
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
  ]
}
