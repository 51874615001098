import { useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { AddEditDomainContext } from 'features/inventory/domain/contexts/AddEditDomainContext'
import { useAbility } from 'providers/casl'
import ProductAdUnitsTable from '../../components/ProductAdUnitsTable'
import BasicSettingsSection from './components/BasicSettingsSection'
import { TargetingSection } from './components/TargetingSection'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/domain', 'common'])
  const { domain } = useContext(AddEditDomainContext)
  const { control } = useFormContext<Schema>()

  if (domain === undefined) {
    throw Error('Domain should be accessible')
  }
  const { id } = domain

  const cannotUpdateDomain: boolean = ability.cannot('update', 'Domain')
  const cannotUpdateRefresher: boolean = ability.cannot('update', 'ProductToggle-refresher')

  return (
    <>
      <Paper data-cy='refresher-status-section'>
        <SectionHeader
          subtitle={t('form.refresher.enabled.description')}
          title={t('form.refresher.enabled.title')}
          titleEndAdornment={
            <Switch
              control={control}
              disabled={cannotUpdateDomain || cannotUpdateRefresher}
              name='refresherEnabled'
            />
          }
        />
      </Paper>

      <BasicSettingsSection />
      <TargetingSection />

      <Controller
        control={control}
        name='adUnitsAttributes'
        render={() => (
          <ProductAdUnitsTable
            domainId={id}
            product='refresher'
          />
        )}
      />
    </>
  )
}
