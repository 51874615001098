import { Can } from 'providers/casl'

export const onGlobalSetupAccess = (can: Can) => {
  can('access', 'GlobalSetupPage-general')
  can('access', 'GlobalSetupPage-prebid-protection-list')
  can('access', 'GlobalSetupPage-prebid-protection-edit')
  can('access', 'GlobalSetupPage-viewport-list')
}

export const onGlobalSetupPriceGeniusRead = (can: Can) => {
  can('read', 'GlobalSetupField-priceGenius')
}

export const onGlobalSetupPriceGeniusUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-priceGenius')
}

export const onGlobalSetupWorkspaceUpdate = (can: Can) => {
  can('update', 'GlobalSetupField-workspace')
}

export const onGlobalSetupWorkspaceUserACUD = (can: Can) => {
  can('access', 'GlobalSetupPage-workspaceUser-add')
  can('access', 'GlobalSetupPage-workspaceUser-edit')
  can('create', 'WorkspaceUser')
  can('update', 'WorkspaceUser')
  can('delete', 'WorkspaceUser')
}
